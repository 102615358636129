import { DELIVERY_TYPES } from "../constants/deliveryTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  createDeliveryByPharmacy,
  updateDeliveryByPharmacy,
  deleteDeliveryByPharmacy,
  fetchDeliveriesByPharmacy,
  fetchDeliveryReportsByPharmacy,
} from "../../api/deliveryApi";
import { ErrorNotification } from "../../Components/Notifications/ToastNotifications";

// Create Delivery Action
export const createDeliveryByPharmacyAction =
  (pharmacyId, deliveryData) => async (dispatch) => {
    try {
      const res = await createDeliveryByPharmacy(pharmacyId, deliveryData);
      dispatch({
        type: DELIVERY_TYPES.CREATE_DELIVERY,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Update Delivery Action
export const updateDeliveryByPharmacyAction =
  (pharmacyId, id, deliveryData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });
    try {
      const res = await updateDeliveryByPharmacy(pharmacyId, id, deliveryData);

      dispatch({
        type: DELIVERY_TYPES.UPDATE_DELIVERY,
        payload: { id, ...res.data },
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
// Delete Delivery Action
export const deleteDeliveryByPharmacyAction =
  (pharmacyId, id) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deleteDeliveryByPharmacy(pharmacyId, id);

      dispatch({
        type: DELIVERY_TYPES.DELETE_DELIVERY,
        payload: id,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };
// Fetch Deliveries Action
export const fetchDeliveriesByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchDeliveriesByPharmacy(pharmacyId);

      dispatch({
        type: DELIVERY_TYPES.FETCH_DELIVERIES,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
// Fetch Deliveries Action
export const fetchDeliveryReportsByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchDeliveryReportsByPharmacy(pharmacyId);

      //  Filter out reports with no patients
      const validReports = res.data.filter(
        (report) => report.patientRef !== null
      );
      dispatch({
        type: DELIVERY_TYPES.FETCH_DELIVERY_REPORTS,
        payload: validReports,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
