import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientsByPharmacyAction } from "../../../redux/actions/patientAction";
import { fetchCareHomesByPharmacyAction } from "../../../redux/actions/careHomeActions";
import { fetchShelvesByPharmacyAction } from "../../../redux/actions/shelveAction";
import { fetchRoutesByPharmacyAction } from "../../../redux/actions/routeAction";
import { fetchCollectionsByPharmacyAction } from "../../../redux/actions/collectionActions";
import { fetchDeliveriesByPharmacyAction } from "../../../redux/actions/deliveryAction";
import { fetchMembersByPharmacyAction } from "../../../redux/actions/memberAction";
import { fetchAppointmentsByPharmacyAction } from "../../../redux/actions/appointmentActions";
import { fetchMdsByPharmacyAction } from "../../../redux/actions/mdsActions";
import { useParams } from "react-router-dom";
import PatientLineGraph from "../../../Components/AdminGraphs/PatientLineGraph";
import AdminDashboardBox from "../../../Components/AdminDashboardBox";
import {
  MdOutlineArchive,
  MdOutlineHome,
  MdOutlineMedicalInformation,
} from "react-icons/md";
import { HiOutlineUserGroup, HiOutlineTruck } from "react-icons/hi2";
import ShelveMixBarGraph from "../../../Components/AdminGraphs/ShelveMixBarGraph";
import CollectionGraph from "../../../Components/AdminGraphs/CollectionGraph";
import "./dashboard.css";
import DeliveryListGraph from "../../../Components/AdminGraphs/DeliveryGraph";
import { fetchVehiclesByPharmacyAction } from "../../../redux/actions/vehicleAction";
import { fetchNotificationsByPharmacyAction } from "../../../redux/actions/notificationActions";
import { fetchMdsRepeatCyclesByPharmacyAction } from "../../../redux/actions/mdsRepeatCycleAction";
import DeliveryLineGraph from "../../../Components/AdminGraphs/DeliveryLineGraph";
import RouteGraph from "../../../Components/AdminGraphs/RouteGraph";
import MdsRepeatCycleGraph from "../../../Components/AdminGraphs/MdsRepeatCycleGraph";
import DashboardCalendar from "../../../Components/AdminGraphs/DashboardCalendar";
import PrescriptionRequest from "../../../Components/PrescriptionRequest";
function Dashboard() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [unreadNotifications, setUnreadNotifications] = useState([]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const user = useSelector((state) => state.auth.user);
  const patients = useSelector((state) => state.patient.patients);
  const careHomes = useSelector((state) => state.careHome.careHomes);
  const shelves = useSelector((state) => state.shelve.shelves);
  const routes = useSelector((state) => state.route.routes);
  const collections = useSelector((state) => state.collection.collections);
  const deliveries = useSelector((state) => state.delivery.deliveries);
  const members = useSelector((state) => state.member.members);
  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const mdsData = useSelector((state) => state.mds.mdsState);
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const mdsRepeatCycles = useSelector(
    (state) => state.mdsRepeatCycle.mdsRepeatCycles
  );
  const appointments = useSelector((state) => state.appointment.appointments);
  useEffect(() => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId));
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId));
    dispatch(fetchShelvesByPharmacyAction(pharmacyId));
    dispatch(fetchRoutesByPharmacyAction(pharmacyId));
    dispatch(fetchRoutesByPharmacyAction(pharmacyId));
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
    dispatch(fetchDeliveriesByPharmacyAction(pharmacyId));
    dispatch(fetchMembersByPharmacyAction(pharmacyId));
    dispatch(fetchVehiclesByPharmacyAction(pharmacyId));
    dispatch(fetchNotificationsByPharmacyAction(pharmacyId));
    dispatch(fetchMdsRepeatCyclesByPharmacyAction(pharmacyId));
    dispatch(fetchAppointmentsByPharmacyAction(pharmacyId));
    dispatch(fetchMdsByPharmacyAction(pharmacyId));
  }, [dispatch, pharmacyId]);

  useEffect(() => {
    const filteredUnread = notifications.filter(
      (notification) => !notification.isRead
    );

    setUnreadNotifications(filteredUnread);
  }, [notifications]);

  const todaysDeliveries = deliveries.filter((delivery) => {
    const deliveryDate = new Date(delivery.deliveryDate);
    deliveryDate.setHours(0, 0, 0, 0);
    return deliveryDate.getTime() === today.getTime();
  });

  const indicators = [
    {
      title: "Total Care Homes",
      count: careHomes.length,
      icon: <MdOutlineHome />,
      iconBackgroundColor: "#735dff",
    },
    {
      title: "Total Shelves",
      count: shelves.length,
      icon: <MdOutlineArchive />,
      iconBackgroundColor: "#ff5a29",
    },
    {
      title: "Total Routes",
      count: routes.length,
      icon: <MdOutlineArchive />,
      iconBackgroundColor: "#0cc763",
    },
    {
      title: "Total Vehicles",
      count: vehicles.length,
      icon: <HiOutlineTruck />,
      iconBackgroundColor: "#0c9cfc",
    },
    {
      title: "Total Members",
      count: members.length,
      icon: <HiOutlineUserGroup />,
      iconBackgroundColor: "#ffb300",
    },
  ];

  return (
    <div className="mt-60">
      <div className="container-fluid">
        <div className="dashboard-welcome-box col-lg-12">
          <h1 className="dashboard-welcome-title">Welcome Back, {user.name}</h1>
          <p className="dashboard-welcome-text">
            Let's dive in and get things done! You have a total of{" "}
            {unreadNotifications.length} unread notifications and{" "}
            {todaysDeliveries.length} deliveries are due today.
          </p>
        </div>
        <div className="row dashboard-main-box">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <AdminDashboardBox
              indicators={indicators}
              pharmacyId={pharmacyId}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <DashboardCalendar appointments={appointments} />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <PrescriptionRequest mdsData={mdsData} pharmacyId={pharmacyId} />
          </div>
          <div className="col-lg-12 col-md-12">
            <PatientLineGraph patients={patients} />
          </div>
          <div className="col-lg-6 col-md-12">
            <ShelveMixBarGraph shelves={shelves} />
          </div>
          <div className="col-lg-6 col-md-12">
            <CollectionGraph collections={collections} />
          </div>
          <div className="col-lg-4 col-md-12">
            <DeliveryListGraph
              deliveries={deliveries}
              members={members}
              shelves={shelves}
            />
          </div>
          <div className="col-lg-8 col-md-12">
            <DeliveryLineGraph deliveries={deliveries} />
          </div>
          <div className="col-lg-5 col-md-12">
            <RouteGraph routes={routes} deliveries={deliveries} />
          </div>
          <div className="col-lg-7 col-md-12">
            <MdsRepeatCycleGraph mdsRepeatCycles={mdsRepeatCycles} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
