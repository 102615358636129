import React, { useMemo, useState } from "react";
import { Table, Button } from "react-bootstrap";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import "./deliveryListGraph.css";

const COLORS = ["#735dff", "#ff5a29", "#0cc763", "#0c9cfc", "#ffb300"];

const DeliveryListGraph = ({ deliveries, members, shelves }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [currentPage, setCurrentPage] = useState(1);
  const deliveriesPerPage = 5;
  const [highlightedSlice, setHighlightedSlice] = useState(null);

  const data = useMemo(() => {
    const initialData = {
      Today: 0,
      Past: 0,
      "Upcoming 2-3 Days": 0,
      "Later than 5 Days": 0,
    };

    deliveries.forEach((delivery) => {
      const deliveryDate = new Date(delivery.deliveryDate);
      deliveryDate.setHours(0, 0, 0, 0);
      const diffTime = deliveryDate - today;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);

      if (diffDays === 0) {
        initialData.Today++;
      } else if (diffDays < 0) {
        initialData.Past++;
      } else if (diffDays > 0 && diffDays <= 3) {
        initialData["Upcoming 2-3 Days"]++;
      } else if (diffDays > 5) {
        initialData["Later than 5 Days"]++;
      }
    });

    return Object.entries(initialData).map(([name, value]) => ({
      name,
      value,
    }));
  }, [deliveries]);

  const todaysDeliveries = deliveries.filter((delivery) => {
    const deliveryDate = new Date(delivery.deliveryDate);
    deliveryDate.setHours(0, 0, 0, 0);
    return deliveryDate.getTime() === today.getTime();
  });

  // Pagination logic
  const indexOfLastDelivery = currentPage * deliveriesPerPage;
  const indexOfFirstDelivery = indexOfLastDelivery - deliveriesPerPage;
  const currentDeliveries = todaysDeliveries.slice(
    indexOfFirstDelivery,
    indexOfLastDelivery
  );

  const totalPages = Math.ceil(todaysDeliveries.length / deliveriesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="delivery-graphs-container">
      <div className="delivery-title-box">
        <div className="delivery-dashboard-title">
          Upcoming Deliveries Overview
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                opacity={highlightedSlice === entry.name ? 1 : 0.6}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            onMouseEnter={(o) => setHighlightedSlice(o.value)}
            onMouseLeave={() => setHighlightedSlice(null)}
            formatter={(value) => `${value}`}
            verticalAlign="top"
            align="left"
            wrapperStyle={{
              fontSize: "12px",
              color: "#666",
              lineHeight: "16px",
              marginLeft: "-10px",
              padding: "0 10px",
            }}
          />
        </PieChart>
      </ResponsiveContainer>

      <div className="delivery-title-box">
        <div className="delivery-dashboard-title">Today's Deliveries</div>
      </div>
      <div className="table-box">
        <Table className="table table-bordered w-100">
          <thead className="delivery-dashboard-table-header">
            <tr>
              <th>Patient Name</th>
              <th>Route Assigned</th>
              <th>Delivery Type</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="delivery-dashboard-table-body">
            {currentDeliveries.map((delivery) => (
              <tr key={delivery._id}>
                <td>
                  {delivery.patientRef.displayName ||
                    `${delivery.patientRef.title} ${delivery.patientRef.firstName} ${delivery.patientRef.lastName}`}
                </td>
                <td>{delivery.routeRef?.description}</td>
                <td>{delivery.deliveryType}</td>
                <td>{delivery.deliveryStatus}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination Buttons */}
      <div className="delivery-pagination-box">
        <Button
          className="delivery-pagination-btn"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="delivery-page-number">
          Page {currentPage} of {totalPages}
        </span>
        <Button
          className="delivery-pagination-btn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default DeliveryListGraph;
