import React, { useEffect, useState } from "react";
import {
  fetchCollectionsByPharmacyAction,
  switchCollectionType,
} from "../../../redux/actions/collectionActions";
import {
  fetchShelvesByPharmacyAction,
  removeShelfAction,
  reassignShelfAction,
} from "../../../redux/actions/shelveAction";
import { fetchCareHomesByPharmacyAction } from "../../../redux/actions/careHomeActions";
import { useDispatch, useSelector } from "react-redux";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import { useParams } from "react-router-dom";
import { MdHome, MdLocalHospital, MdOutlineOutbox } from "react-icons/md";
import { FaHandHoldingMedical } from "react-icons/fa";
import { Button, Accordion, Modal, Offcanvas, Form } from "react-bootstrap";
import "./handouts.css";

function Handouts() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentCollectionId, setCurrentCollectionId] = useState(null);
  const [newCollectionType, setNewCollectionType] = useState("");
  const [collectionDetails, setCollectionDetails] = useState({
    collectedName: "",
    collectedPhone: "",
    collectedDate: new Date().toISOString().slice(0, 10),
  });
  const [showOffcanvasDetailsModal, setShowOffcanvasDetailsModal] =
    useState(false);

  // Reassign Shelf
  const [showReassignShelfModal, setShowReassignShelfModal] = useState(false);
  const [reassignCapacityNeeded, setReassignCapacityNeeded] = useState(1);
  const [reassignShelfId, setReassignShelfId] = useState("");
  const [editableCollection, setEditableCollection] = useState(null);

  // Pagination for overall patients
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // Pagination for individual collections inside accordion
  const [patientPagination, setPatientPagination] = useState({});

  const collections = useSelector((state) => state.collection.collections);
  const shelves = useSelector((state) => state.shelve.shelves);
  const careHomes = useSelector((state) => state.careHome.careHomes);
  const user = useSelector((state) => state.auth.user);
  const userCurrentPlan = user.currentPlan;

  const careHomeName = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.name : "Care name not set";
  };

  const careHomeAddress = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.address : "Care address not set";
  };

  const openDetailsModal = (collectionId) => {
    setCurrentCollectionId(collectionId);
    setShowOffcanvasDetailsModal(true);
  };

  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    setCollectionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const submitCollectionDetails = async () => {
    try {
      await dispatch(
        removeShelfAction(
          pharmacyId,
          currentCollectionId,
          "Collected",
          collectionDetails
        )
      );
      setShowOffcanvasDetailsModal(false);
      dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
      dispatch(
        createNotificationByPharmacyAction(pharmacyId, {
          content: `Collection with ID ${currentCollectionId} was collected by ${collectionDetails.collectedName} on ${collectionDetails.collectedDate}`,
          user: user._id,
          forRole: ["admin", "manager"],
          priority: "high",
        })
      );
    } catch (error) {
      ErrorNotification("Failed to update collection details");
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.trim().toLowerCase());
    setCurrentPage(1); // Reset to first page on search
  };

  const getShelfNameByCollection = (collectionId) => {
    const shelf = shelves.find((shelf) =>
      shelf.collectionRef.includes(collectionId)
    );
    return shelf ? shelf.name : "Unknown Shelf";
  };

  const promptSwitchCollectionType = (collectionId, collectionType) => {
    setCurrentCollectionId(collectionId);
    setNewCollectionType(collectionType);
    setShowConfirmationModal(true);
  };

  const confirmSwitchCollectionType = () => {
    if (currentCollectionId && newCollectionType) {
      dispatch(switchCollectionType(currentCollectionId, newCollectionType));
      setShowConfirmationModal(false);
      dispatch(
        createNotificationByPharmacyAction(pharmacyId, {
          content: `Collection type switched to '${newCollectionType}' for collection ID ${currentCollectionId}`,
          user: user._id,
          forRole: ["admin"],
          priority: "medium",
        })
      );
    }
  };

  const filteredCollections = collections.filter((collection) => {
    const fullName = `${collection.patient?.firstName ?? ""} ${
      collection.patient?.middleName ?? ""
    } ${collection.patient?.lastName ?? ""}`.toLowerCase();

    const patientAddress = `${collection.patient?.addressLine1 ?? ""} ${
      collection.patient?.addressLine2 ?? ""
    } ${collection.patient?.townName ?? ""} ${
      collection.patient?.postCode ?? ""
    }`.toLowerCase();

    const careHome = careHomes.find(
      (home) => home._id === collection.patient?.careHome
    );
    const careHomeAddress = careHome
      ? `${careHome.name ?? ""} ${careHome.address ?? ""}`.toLowerCase()
      : "";

    const nhsNumber = collection.patient?.nhsNumber?.toLowerCase() ?? "";

    return (
      collection.collectionType === "handout" &&
      collection.status === "Pending" &&
      (fullName.includes(searchQuery) ||
        patientAddress.includes(searchQuery) ||
        careHomeAddress.includes(searchQuery) || // Include care home address in the search
        nhsNumber.includes(searchQuery))
    );
  });

  const groupCollectionsByPatient = (collections) => {
    return collections.reduce((acc, collection) => {
      const patientId = collection?.patient?._id;
      if (!acc[patientId]) {
        acc[patientId] = {
          patient: collection.patient,
          collections: [],
        };
      }
      acc[patientId].collections.push(collection);
      return acc;
    }, {});
  };

  const groupedCollections = groupCollectionsByPatient(filteredCollections);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1);
  const handlePrevPage = () =>
    currentPage > 1 && setCurrentPage(currentPage - 1);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on changing items per page
  };

  const handlePatientPageChange = (patientId, pageNumber) => {
    setPatientPagination((prev) => ({
      ...prev,
      [patientId]: {
        ...prev[patientId],
        currentPage: pageNumber,
      },
    }));
  };

  const handlePatientItemsPerPageChange = (patientId, itemsPerPage) => {
    setPatientPagination((prev) => ({
      ...prev,
      [patientId]: {
        ...prev[patientId],
        itemsPerPage,
        currentPage: 1, // Reset to first page on changing items per page
      },
    }));
  };

  const getPaginatedCollections = (patientId, collections) => {
    const patientCurrentPage = patientPagination[patientId]?.currentPage || 1;
    const patientItemsPerPage = patientPagination[patientId]?.itemsPerPage || 5;
    const indexOfLastItem = patientCurrentPage * patientItemsPerPage;
    const indexOfFirstItem = indexOfLastItem - patientItemsPerPage;
    return collections.slice(indexOfFirstItem, indexOfLastItem);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentGroupedCollections = Object.entries(groupedCollections).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(
    Object.keys(groupedCollections).length / itemsPerPage
  );

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleReassignShelf = () => {
    console.log(
      editableCollection,
      reassignShelfId,
      reassignCapacityNeeded,
      "data"
    );
    if (!reassignShelfId || !editableCollection || !editableCollection._id) {
      ErrorNotification(
        "Please select a valid shelf and specify the capacity needed."
      );
      return;
    }

    dispatch(
      reassignShelfAction(
        pharmacyId,
        editableCollection._id,
        reassignShelfId,
        reassignCapacityNeeded
      )
    )
      .then(() => {
        dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
        dispatch(fetchShelvesByPharmacyAction(pharmacyId));
        setShowReassignShelfModal(false);
        // Add notification for successful reassignment
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Shelf reassigned successfully for collection.`,
            user: user._id,
            forRole: ["admin", "manager"],
            priority: "medium",
          })
        );
      })
      .catch((err) => {
        ErrorNotification(
          "Error during shelf reassignment: " + (err.message || "Unknown error")
        );
      });
  };

  useEffect(() => {
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching collection", err);
    });
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching shelves", err);
    });
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching careHomes", err);
    });
  }, [dispatch, pharmacyId]);

  return (
    <div className="handout-container">
      {userCurrentPlan === "Free" && (
        <div className="information-text">
          <p>Setting Collection type to "delivery" is disabled for Free Plan</p>
        </div>
      )}
      <div className="handout-search-input-box">
        <input
          type="text"
          className="handout-search-input"
          placeholder="Search By Name, NHS, Address"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      <div className="handout-header">
        <div className="handout-legends">
          <div className="handout-legend-item">
            <FaHandHoldingMedical className="handout-legend-icon handout-legend-collected-by" />
            <span className="handout-legend-text">Collected By</span>
          </div>
          <div className="handout-legend-item">
            <MdHome className="handout-legend-icon handout-legend-patients-address" />
            <span className="handout-legend-text">Patient Address</span>
          </div>
          <div className="handout-legend-item">
            <MdLocalHospital className="handout-legend-icon handout-legend-carehome-address" />
            <span className="handout-legend-text">Care Home Address</span>
          </div>
          <div className="handout-legend-item">
            <MdOutlineOutbox className="handout-legend-icon handout-legend-reassign-icon" />
            <span className="handout-legend-text">Reassign Shelf</span>
          </div>
        </div>
      </div>

      <div className="handout-items-per-page-selector">
        <select
          className="handout-form-select"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
        >
          {[5, 10, 15, 20].map((number) => (
            <option
              key={number}
              value={number}
              className="handout-items-per-page-option"
            >
              {number} Patients/page
            </option>
          ))}
        </select>
      </div>

      <div className="handout-accordion-container">
        <Accordion>
          {currentGroupedCollections.map(([patientId, data], index) => {
            const paginatedCollections = getPaginatedCollections(
              patientId,
              data.collections
            );
            const totalPatientPages = Math.ceil(
              data.collections.length /
                (patientPagination[patientId]?.itemsPerPage || 5)
            );

            return (
              <Accordion.Item eventKey={index.toString()} key={patientId}>
                <Accordion.Header>
                  <div className="handout-accordion-header">
                    <div>
                      <p className="handout-accordion-patient-name">
                        {data.patient?.firstName} {data.patient?.lastName}{" "}
                      </p>
                      <p className="handout-accordion-patient-nhs">
                        NHS: {data.patient?.nhsNumber}
                      </p>
                    </div>
                    <div className="handout-accordion-patient-address">
                      {data.patient?.careHome ? (
                        <div className="handout-create-patient-address">
                          <div className="d-flex align-items-center">
                            <MdLocalHospital
                              size={24}
                              color="#0cc763"
                              style={{ marginRight: "8px" }}
                            />
                          </div>
                          <div>
                            <p className="handout-create-patient-carehome-name">
                              {careHomeName(data.patient?.careHome)}
                            </p>
                            <div>{careHomeAddress(data.patient?.careHome)}</div>
                          </div>
                        </div>
                      ) : (
                        <div className="handout-create-patient-address">
                          <div className="d-flex align-items-center">
                            <MdHome
                              size={24}
                              color="#ffb300"
                              style={{ marginRight: "8px" }}
                            />
                          </div>
                          <div>
                            {data.patient?.addressLine1 &&
                              `${
                                data.patient?.addressLine1.trim().endsWith(",")
                                  ? data.patient?.addressLine1
                                  : data.patient?.addressLine1 + ","
                              } `}
                            {data.patient?.addressLine2 &&
                              `${
                                data.patient?.addressLine2.trim().endsWith(",")
                                  ? data.patient?.addressLine2
                                  : data.patient?.addressLine2 + ","
                              } `}
                            {data.patient?.townName &&
                              `${data.patient?.townName}, `}
                            {data.patient?.postCode}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="handout-table-container">
                    <table className="table table-bordered w-100">
                      <thead>
                        <tr>
                          <th>Shelf Name</th>
                          <th>Created At</th>
                          <th>Collection Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedCollections.map(
                          (collection, collectionIndex) => (
                            <tr key={collectionIndex}>
                              <td>
                                {getShelfNameByCollection(collection._id)}
                              </td>
                              <td>{formatDate(collection.createdAt)}</td>
                              <td>
                                <select
                                  value={collection.collectionType}
                                  onChange={(e) =>
                                    promptSwitchCollectionType(
                                      collection._id,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="handout">Handout</option>
                                  {userCurrentPlan === "Free" ? (
                                    <option value="delivery" disabled>
                                      delivery
                                    </option>
                                  ) : (
                                    <option value="delivery">delivery</option>
                                  )}
                                </select>
                              </td>
                              <td>
                                <div className="handout-collect-button-container">
                                  <Button
                                    className="handout-collect-button"
                                    onClick={() =>
                                      openDetailsModal(collection._id)
                                    }
                                  >
                                    <FaHandHoldingMedical />
                                  </Button>
                                  <Button
                                    className="handout-reassign-button"
                                    onClick={() => {
                                      setEditableCollection(collection);
                                      setShowReassignShelfModal(true);
                                    }}
                                  >
                                    <MdOutlineOutbox />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    <div className="page-number-box">
                      <div>
                        <select
                          className="form-select"
                          value={
                            patientPagination[patientId]?.itemsPerPage || 5
                          }
                          onChange={(e) =>
                            handlePatientItemsPerPageChange(
                              patientId,
                              Number(e.target.value)
                            )
                          }
                        >
                          {[5, 10, 15, 20].map((number) => (
                            <option key={number} value={number}>
                              {number} Collections/page
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="pagination">
                        {[...Array(totalPatientPages).keys()].map(
                          (pageNumber) => (
                            <Button
                              key={pageNumber}
                              onClick={() =>
                                handlePatientPageChange(
                                  patientId,
                                  pageNumber + 1
                                )
                              }
                              className={`page-link ${
                                (patientPagination[patientId]?.currentPage ||
                                  1) ===
                                pageNumber + 1
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {pageNumber + 1}
                            </Button>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>

      <div className="page-number-box">
        {/* Pagination */}
        <div className="handout-pagination">
          <Button
            className="handout-pagination-btn"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="handout-page-number">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            className="handout-pagination-btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        centered
        className="handouts-confirmation-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Switch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to switch the collection type?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmSwitchCollectionType}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Details Modal */}
      <Offcanvas
        show={showOffcanvasDetailsModal}
        onHide={() => setShowOffcanvasDetailsModal(false)}
        placement="end"
        className="handouts-offcanvas"
      >
        <Offcanvas.Header closeButton className="handouts-offcanvas-header">
          <Offcanvas.Title className="handouts-offcanvas-title">
            Collection Details
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="handouts-offcanvas-body">
          <form
            className="handouts-offcanvas-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="mb-3">
              <label htmlFor="collectedName" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="collectedName"
                name="collectedName"
                value={collectionDetails.collectedName}
                onChange={handleDetailChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="collectedPhone" className="form-label">
                Phone
              </label>
              <input
                type="number"
                className="form-control"
                id="collectedPhone"
                name="collectedPhone"
                value={collectionDetails.collectedPhone}
                onChange={handleDetailChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="collectedDate" className="form-label">
                Date
              </label>
              <input
                type="date"
                className="form-control"
                id="collectedDate"
                name="collectedDate"
                value={collectionDetails.collectedDate}
                onChange={handleDetailChange}
              />
            </div>
          </form>
        </Offcanvas.Body>
        <div className="handouts-offcanvas-footer">
          <Button
            variant="secondary"
            onClick={() => setShowOffcanvasDetailsModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={submitCollectionDetails}>
            Save changes
          </Button>
        </div>
      </Offcanvas>

      {/* Reassign Shelf Modal */}
      <Offcanvas
        show={showReassignShelfModal}
        onHide={() => setShowReassignShelfModal(false)}
        placement="end"
        className="handouts-offcanvas"
      >
        <Offcanvas.Header closeButton className="handouts-offcanvas-header">
          <Offcanvas.Title className="handouts-offcanvas-title">
            Reassign Shelf
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="handouts-offcanvas-body">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Select New Shelf</Form.Label>
              <Form.Select
                value={reassignShelfId}
                onChange={(e) => setReassignShelfId(e.target.value)}
              >
                <option value="">Select a shelf...</option>
                {shelves.map((shelf) => (
                  <option
                    key={shelf._id}
                    value={shelf._id}
                    disabled={
                      shelf.occupiedCapacity + reassignCapacityNeeded >
                      shelf.shelfCapacity
                    }
                  >
                    {shelf.name} ({shelf.occupiedCapacity}/{shelf.shelfCapacity}{" "}
                    occupied)
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Capacity Needed</Form.Label>
              <Form.Control
                type="number"
                value={reassignCapacityNeeded}
                onChange={(e) =>
                  setReassignCapacityNeeded(parseInt(e.target.value))
                }
                min="1"
              />
            </Form.Group>
          </Form>
        </Offcanvas.Body>
        <div className="handouts-offcanvas-footer">
          <Button
            variant="secondary"
            onClick={() => setShowReassignShelfModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleReassignShelf}
            disabled={!reassignShelfId}
          >
            Reassign Shelf
          </Button>
        </div>
      </Offcanvas>
    </div>
  );
}

export default Handouts;
