import React, { useEffect, useState } from "react";
import { NavLink, Link, Outlet, useLocation } from "react-router-dom";
import medtrakrLogo from "../../images/logo/logoicon-01.png";
import { BiHomeHeart, BiClinic } from "react-icons/bi";
import { BsBookshelf, BsClipboard2Plus } from "react-icons/bs";
import {
  FaRoute,
  FaHandHoldingMedical,
  FaRegCalendarAlt,
  FaNotesMedical,
  FaBookMedical,
} from "react-icons/fa";
import {
  FaTruckMedical,
  FaHospitalUser,
  FaClipboardUser,
  FaHouseMedicalFlag,
  FaTruckFast,
  FaBoxArchive,
} from "react-icons/fa6";
import {
  MdMedicalInformation,
  MdShelves,
  MdOutlineDeliveryDining,
  MdOutlineEditCalendar,
  MdOutlineCollectionsBookmark,
} from "react-icons/md";
import { GiCardExchange } from "react-icons/gi";
import { LuCalendarClock } from "react-icons/lu";
import { TbMap2, TbReportAnalytics } from "react-icons/tb";
import { useSidebar } from "../../contexts/SidebarContext";
import "./adminSidebar.css";

function AdminSideBar({ pharmacyId }) {
  const { isSidebarExpanded, setSidebarIsExpanded } = useSidebar();
  const location = useLocation();

  const [dropdowns, setDropdowns] = useState({
    patients: false,
    collections: false,
    deliveries: false,
    mds: false,
    appointments: false,
    reports: false,
    management: false,
  });

  const toggleDropdown = (dropdown) => {
    setDropdowns((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === dropdown ? !prev[key] : false;
        return acc;
      }, {});
      return newState;
    });
  };

  const getTextStyle = () => ({
    overflow: isSidebarExpanded ? "visible" : "hidden",
    whiteSpace: isSidebarExpanded ? "normal" : "nowrap",
    width: isSidebarExpanded ? "auto" : "0",
    transition: "width 0.3s ease",
    fontSize: "1rem",
  });

  useEffect(() => {
    const path = location.pathname;
    if (
      path.includes("create-member") ||
      path.includes("care-home") ||
      path.includes("create-vehicle") ||
      path.includes("collection-shelves") ||
      path.includes("create-routes")
    ) {
      setDropdowns((prev) => ({ ...prev, management: true }));
    } else if (path.includes("create-patient")) {
      setDropdowns((prev) => ({ ...prev, patients: true }));
    } else if (path.includes("storage")) {
      setDropdowns((prev) => ({ ...prev, collections: true }));
    } else if (
      path.includes("create-delivery") ||
      path.includes("delivery-list")
    ) {
      setDropdowns((prev) => ({ ...prev, deliveries: true }));
    } else if (path.includes("request-rx") || path.includes("update-rx")) {
      setDropdowns((prev) => ({ ...prev, mds: true }));
    } else if (path.includes("appointment")) {
      setDropdowns((prev) => ({ ...prev, appointments: true }));
    } else if (
      path.includes("collection-reports") ||
      path.includes("delivery-reports") ||
      path.includes("mds-reports")
    ) {
      setDropdowns((prev) => ({ ...prev, reports: true }));
    }
  }, [location.pathname]);

  return (
    <div
      className={`sidebar`}
      onMouseEnter={() => setSidebarIsExpanded(true)}
      onMouseLeave={() => setSidebarIsExpanded(false)}
      style={{
        width: isSidebarExpanded ? "200px" : "60px",
        transition: "width 0.3s ease",
      }}
    >
      <div className="sidebar-content">
        <div className="text-center my-3">
          <Link
            to={`/admin/${pharmacyId}/dashboard`}
            style={{ justifyContent: "center" }}
          >
            <img
              className="logo-small"
              src={medtrakrLogo}
              alt="logo"
              style={{ maxWidth: "35px" }}
            />
          </Link>
        </div>
        <ul className="sidebar-ul pt-4">
          <li>
            <div className="w-100 sidebar-div ">
              <NavLink
                to={`/admin/${pharmacyId}/dashboard`}
                className={({ isActive }) =>
                  isActive ? "nav-item-active" : "nav-item"
                }
              >
                <div className="admin-nav">
                  <i className="fas fa-tachometer-alt"></i>
                  <span style={getTextStyle()}>Dashboard</span>
                </div>
              </NavLink>
            </div>
          </li>

          <li>
            <div
              className="w-100 sidebar-div"
              onClick={() => toggleDropdown("management")}
            >
              <div className="admin-nav">
                <FaHospitalUser className="nav-link-icon" />
                <span style={getTextStyle()}>Management</span>
              </div>
              <i
                className="fas fa-caret-down nav-item"
                style={getTextStyle()}
              ></i>
            </div>
            {dropdowns.management ? (
              <div>
                <ul className="sidebar-ul">
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/create-member`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <MdMedicalInformation className="nav-link-icon" />
                        <span style={getTextStyle()}>Team</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/care-home`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <BiHomeHeart className="nav-link-icon" />
                        <span style={getTextStyle()}>Care Home</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/create-vehicle`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <FaTruckFast className="nav-link-icon" />
                        <span style={getTextStyle()}>Vehicle</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/create-routes`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <FaRoute className="nav-link-icon" />
                        <span style={getTextStyle()}>Routes</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/collection-shelves`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <BsBookshelf className="nav-link-icon" />
                        <span style={getTextStyle()}>Shelves</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/switch-pharmacy`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <GiCardExchange className="nav-link-icon" />
                        <span style={getTextStyle()}>Switch Pharmacy</span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </li>

          <li>
            <div className="w-100 sidebar-div ">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-item-active" : "nav-item"
                }
                to={`/admin/${pharmacyId}/create-patient`}
              >
                <div className="admin-nav">
                  <FaClipboardUser className="nav-link-icon" />
                  <span style={getTextStyle()}>Patients</span>
                </div>
              </NavLink>
            </div>
          </li>
          <li>
            <div className="w-100 sidebar-div ">
              <NavLink
                to={`/admin/${pharmacyId}/storage`}
                className={({ isActive }) =>
                  isActive ? "nav-item-active" : "nav-item"
                }
              >
                <div className="admin-nav">
                  <MdShelves className="nav-link-icon" />
                  <span style={getTextStyle()}>Rx Storage</span>
                </div>
              </NavLink>
            </div>
          </li>
          <li>
            <div className="w-100 sidebar-div ">
              <NavLink
                to={`/admin/${pharmacyId}/handouts`}
                className={({ isActive }) =>
                  isActive ? "nav-item-active" : "nav-item"
                }
              >
                <div className="admin-nav">
                  <FaHandHoldingMedical className="nav-link-icon" />
                  <span style={getTextStyle()}>Handouts</span>
                </div>
              </NavLink>
            </div>
          </li>

          <li>
            <div
              className="w-100 sidebar-div "
              onClick={() => {
                toggleDropdown("deliveries");
              }}
            >
              <div className="admin-nav">
                <MdOutlineDeliveryDining className="nav-link-icon" />
                <span style={getTextStyle()}>Deliveries</span>
              </div>
              <i
                className="fas fa-caret-down nav-item"
                style={getTextStyle()}
              ></i>
            </div>
            {dropdowns.deliveries ? (
              <div>
                <ul className="sidebar-ul">
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/create-delivery`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <FaTruckMedical className="nav-link-icon" />
                        <span style={getTextStyle()}>Create Deliveries</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/delivery-list`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <FaNotesMedical className="nav-link-icon" />
                        <span style={getTextStyle()}>Delivery List</span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </li>

          <li>
            <div
              className="w-100 sidebar-div "
              onClick={() => {
                toggleDropdown("mds");
              }}
            >
              <div className="admin-nav">
                <FaHouseMedicalFlag className="nav-link-icon" />
                <span style={getTextStyle()}>MDS</span>
              </div>
              <i
                className="fas fa-caret-down nav-item"
                style={getTextStyle()}
              ></i>
            </div>
            {dropdowns.mds ? (
              <div>
                <ul className="sidebar-ul">
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/request-rx`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <MdMedicalInformation className="nav-link-icon" />
                        <span style={getTextStyle()}>Set Repeat</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/update-rx`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <FaBookMedical className="nav-link-icon" />
                        <span style={getTextStyle()}>Update</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/reminder-rx`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <LuCalendarClock className="nav-link-icon" />
                        <span style={getTextStyle()}>Request</span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </li>

          <li>
            <div
              className="w-100 sidebar-div "
              onClick={() => {
                toggleDropdown("appointments");
              }}
            >
              <div className="admin-nav">
                <FaRegCalendarAlt className="nav-link-icon" />
                <span style={getTextStyle()}>Appointments</span>
              </div>
              <i
                className="fas fa-caret-down nav-item"
                style={getTextStyle()}
              ></i>
            </div>
            {dropdowns.appointments ? (
              <div>
                <ul className="sidebar-ul">
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/appointment`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <MdOutlineEditCalendar className="nav-link-icon" />
                        <span style={getTextStyle()}>Book Appointment</span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </li>
          <li>
            <div
              className="w-100 sidebar-div "
              onClick={() => {
                toggleDropdown("reports");
              }}
            >
              <div className="admin-nav">
                <TbReportAnalytics className="nav-link-icon" />
                <span style={getTextStyle()}>Reports</span>
              </div>
              <i
                className="fas fa-caret-down nav-item"
                style={getTextStyle()}
              ></i>
            </div>
            {dropdowns.reports ? (
              <div>
                <ul className="sidebar-ul">
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/collection-reports`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <MdOutlineCollectionsBookmark className="nav-link-icon" />
                        <span style={getTextStyle()}>Collection Report</span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/delivery-reports`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <TbMap2 className="nav-link-icon" />
                        <span style={getTextStyle()}>Delivery Report</span>
                      </div>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`/admin/${pharmacyId}/mds-reports`}
                      className={({ isActive }) =>
                        isActive ? "nav-item-active" : "nav-item"
                      }
                    >
                      <div className="admin-nav">
                        <BsClipboard2Plus className="nav-link-icon" />
                        <span style={getTextStyle()}>MDS Report</span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </li>
        </ul>
      </div>
      <div
        className="outlet"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={{ marginTop: "75px" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSideBar;
