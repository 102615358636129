import React, { useEffect, useState } from "react";
import { fetchCollectionsByPharmacyAction } from "../../../redux/actions/collectionActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, Button, Table, Accordion } from "react-bootstrap";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import "./collectionReports.css";

export default function CollectionReports() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const collections = useSelector((state) => state.collection.collections);

  useEffect(() => {
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching collections", err);
    });
  }, [dispatch, pharmacyId]);

  const applyFilters = (collections) => {
    return collections.filter((collection) => {
      if (collection.collectionType === "delivery") return false;
      const fullName =
        `${collection.patient?.firstName} ${collection.patient?.lastName}`.toLowerCase();
      const nhsNumber = collection.patient?.nhsNumber.toString().toLowerCase();
      const query = searchQuery.toLowerCase();
      const collectionDate = new Date(collection.createdAt);
      const from = fromDate ? new Date(fromDate) : null;
      const to = toDate ? new Date(toDate) : null;
      return (
        (fullName.includes(query) || nhsNumber.includes(query)) &&
        (!from || collectionDate >= from) &&
        (!to || collectionDate <= to)
      );
    });
  };

  const groupCollectionsByPatient = (filteredCollections) => {
    return filteredCollections.reduce((acc, collection) => {
      const patientKey = collection.patient?._id;
      if (!acc[patientKey]) {
        acc[patientKey] = {
          name: `${collection?.patient?.firstName} ${collection?.patient?.lastName}`,
          collections: [],
        };
      }
      acc[patientKey].collections.push(collection);
      return acc;
    }, {});
  };

  const filteredCollections = applyFilters(collections);
  const groupedCollections = groupCollectionsByPatient(filteredCollections);
  const totalItems = Object.keys(groupedCollections).length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentGroupedCollections = Object.entries(groupedCollections).slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
        >
          <Button onClick={() => handlePageChange(i)} className="page-link">
            {i}
          </Button>
        </li>
      );
    }
    return <ul className="pagination">{pages}</ul>;
  };

  const flattenObject = (obj, prefix = "") =>
    Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + "." : "";
      if (
        typeof obj[k] === "object" &&
        obj[k] !== null &&
        !Array.isArray(obj[k]) &&
        !(obj[k] instanceof Date)
      ) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});

  const exportSinglePDF = () => {
    const doc = new jsPDF();
    doc.text(
      `Collection Report for NHS Number: ${selectedCollection.patient?.nhsNumber}`,
      10,
      10
    );
    const flatObject = flattenObject(selectedCollection);
    const lines = Object.entries(flatObject).map(
      ([key, value]) =>
        `${key}: ${typeof value === "object" ? JSON.stringify(value) : value}`
    );
    doc.setFontSize(10);
    lines.forEach((line, i) => {
      doc.text(line, 10, 20 + i * 10);
    });
    doc.save(`${selectedCollection.patient?.nhsNumber}-collection-report.pdf`);
  };

  const exportSingleXLSX = () => {
    const flatObject = flattenObject(selectedCollection);
    const formattedData = Object.keys(flatObject).map((key) => ({
      Field: key,
      Value: flatObject[key],
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData, {
      header: ["Field", "Value"],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Collection Report");

    // Adjust column widths to fit content
    worksheet["!cols"] = [
      { wch: Math.max(...formattedData.map((data) => data.Field.length), 10) },
      {
        wch: Math.max(
          ...formattedData.map((data) => String(data.Value).length),
          20
        ),
      },
    ];

    const fileName = `${selectedCollection.patient?.nhsNumber}-collection-report.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  const printSingleCollection = () => {
    let printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(
      "<html><head><title>Print Collection</title></head><body>"
    );
    const flatObject = flattenObject(selectedCollection);
    const content = Object.entries(flatObject)
      .map(([key, value]) => `<p>${key}: ${value}</p>`)
      .join("");
    printWindow.document.write(`<h3>Collection Details</h3>${content}`);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    }).format(new Date(date));
  };

  return (
    <>
      <div className="mt-100">
        <div className="container-fluid">
          <div className="search-input-box">
            <input
              type="text"
              placeholder="Search by name or NHS number..."
              className="form-control mr-10 search-filter-input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="date-filter-box">
              <input
                type="date"
                className="form-control mb-3"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control mb-3"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <button
                className="btn btn-warning mb-3"
                onClick={() => {
                  setSearchQuery("");
                  setFromDate("");
                  setToDate("");
                  setCurrentPage(1);
                }}
              >
                Reset Filters
              </button>
            </div>
          </div>
          <Accordion defaultActiveKey="0">
            {currentGroupedCollections.map(([patientId, data], index) => (
              <Accordion.Item eventKey={String(index)} key={patientId}>
                <Accordion.Header>
                  {data.name} - {data.collections.length} Collections
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>NHS Number</th>
                        <th>Collection Type</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.collections.map((collection) => (
                        <tr key={collection._id}>
                          <td>{formatDate(collection.createdAt)}</td>
                          <td>{collection.patient?.nhsNumber}</td>
                          <td>{collection.collectionType}</td>
                          <td>{collection.status}</td>
                          <td>
                            <button
                              onClick={() => {
                                setShowModal(true);
                                setSelectedCollection(collection);
                              }}
                              className="btn view"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <div className="page-number-box">
            {renderPageNumbers()}
            <select
              className="form-select"
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
                setCurrentPage(1); // Reset to first page when items per page changes
              }}
            >
              {[5, 10, 15, 20, 25].map((number) => (
                <option key={number} value={number}>
                  Show {number}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Collection Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCollection && (
            <div>
              <p>
                <strong>Date:</strong>{" "}
                {formatDate(selectedCollection.createdAt)}
              </p>
              <p>
                <strong>NHS Number:</strong>{" "}
                {selectedCollection.patient.nhsNumber}
              </p>
              <p>
                <strong>Collection Type:</strong>{" "}
                {selectedCollection.collectionType}
              </p>
              <p>
                <strong>Status:</strong> {selectedCollection.status}
              </p>
              <Button
                variant="primary"
                onClick={exportSinglePDF}
                className="me-2"
              >
                Export as PDF
              </Button>
              <Button
                variant="success"
                onClick={exportSingleXLSX}
                className="me-2"
              >
                Export as XLSX
              </Button>
              <Button variant="secondary" onClick={printSingleCollection}>
                Print
              </Button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
