import api from "./index";

// Fetch Pharmacy
export const fetchPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/pharmacy`);
};
// Super Admin
// Fetch All Pharmacies
export const fetchAllPharmacies = async () => {
  return api.get("/pharmacy-list");
};

// New routes for multi-pharmacy support

// Fetch Additional Pharmacy
export const fetchAdditionalPharmacy = async (pharmacyId) => {
  return await api.post(`/${pharmacyId}/additional-pharmacy`);
};

// Fetch All User Pharmacies
export const fetchAllUserPharmacies = async () => {
  return await api.get("/all-user-pharmacies");
};

// Switch Active Pharmacy
export const switchActivePharmacy = async (pharmacyId) => {
  return await api.post("/switch-pharmacy", { pharmacyId });
};
// New routes for multi-pharmacy support

// Create Additional Pharmacy
export const addNewPharmacy = async (pharmacyData) => {
  return await api.post("/create-additional-pharmacy", pharmacyData);
};
