import React, { useMemo, useState, useCallback } from "react";
import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { format, subMonths } from "date-fns";
import "./deliveryLine.css";

const DeliveryLineGraph = ({ deliveries }) => {
  const [monthsToShow, setMonthsToShow] = useState(3);
  const [highlightedLine, setHighlightedLine] = useState(null); // State to track highlighted line

  const getMonths = (monthsBack) => {
    let months = [];
    let currentDate = new Date();

    for (let i = monthsBack - 1; i >= 0; i--) {
      let pastDate = subMonths(currentDate, i);
      months.push(format(pastDate, "MMM yyyy"));
    }

    return months;
  };

  const data = useMemo(() => {
    const months = getMonths(monthsToShow);
    const initialData = months.map((month) => ({
      month,
      completed: 0,
      pickedUp: 0,
      pending: 0,
    }));

    deliveries.forEach((delivery) => {
      const deliveryMonthYear = format(
        new Date(delivery.deliveryDate),
        "MMM yyyy"
      );
      const dataIndex = initialData.findIndex(
        (data) => data.month === deliveryMonthYear
      );

      if (dataIndex !== -1) {
        const deliveryStatus = delivery.deliveryStatus || "pending";
        if (deliveryStatus === "delivery-completed") {
          initialData[dataIndex].completed++;
        } else if (deliveryStatus === "picked-up") {
          initialData[dataIndex].pickedUp++;
        } else if (deliveryStatus === "pending") {
          initialData[dataIndex].pending++;
        }
      }
    });

    return initialData;
  }, [deliveries, monthsToShow]);

  // Function to assign "active" class based on the current monthsToShow state
  const getButtonClass = (months) => {
    return monthsToShow === months ? "active-months" : "";
  };

  const handleLegendMouseEnter = useCallback((o) => {
    setHighlightedLine(o.dataKey);
  }, []);

  const handleLegendMouseLeave = useCallback(() => {
    setHighlightedLine(null);
  }, []);

  return (
    <div className="delivery-line-graph-container">
      <div className="delivery-dashboard-title">Deliveries</div>
      <div className="linegraph-title-box">
        <div className="linegraph-legend"></div>
        <div className="dashboard-linegraph-title">Delivery Statistics</div>
        <div className="lineGraphButtonBox">
          <button
            className={getButtonClass(3)}
            onClick={() => setMonthsToShow(3)}
          >
            3 Months
          </button>
          <button
            className={getButtonClass(6)}
            onClick={() => setMonthsToShow(6)}
          >
            6 Months
          </button>
          <button
            className={getButtonClass(12)}
            onClick={() => setMonthsToShow(12)}
          >
            12 Months
          </button>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={420}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <Tooltip />
          <Legend
            onMouseEnter={handleLegendMouseEnter}
            onMouseLeave={handleLegendMouseLeave}
            align="left"
            verticalAlign="top"
            wrapperStyle={{
              paddingLeft: 0,
              transform: "translateY(-35px)",
              maxWidth: "200px",
            }}
            formatter={(value, entry, index) => (
              <span
                style={{
                  fontSize: "1rem",
                  fontFamily: "Figtree-SemiBold",
                  marginRight: "10px",
                  textTransform: "capitalize",
                  textShadow: "1px 1px 1px #fff",
                  cursor: "pointer",
                }}
              >
                {value}
              </span>
            )}
          />
          <Line
            type="monotone"
            dataKey="completed"
            stroke="#735dff"
            fill="#735dff26"
            strokeWidth={2}
            opacity={
              highlightedLine && highlightedLine !== "completed" ? 0.2 : 1
            }
            name="Completed"
          />
          <Line
            type="monotone"
            dataKey="pickedUp"
            stroke="#0cc763"
            fill="#0cc76326"
            strokeWidth={2}
            opacity={
              highlightedLine && highlightedLine !== "pickedUp" ? 0.2 : 1
            }
            name="Picked-Up"
          />
          <Line
            type="monotone"
            dataKey="pending"
            stroke="#ff5a29"
            fill="#ff5a2926"
            strokeWidth={2}
            opacity={highlightedLine && highlightedLine !== "pending" ? 0.2 : 1}
            name="Pending"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DeliveryLineGraph;
