import React, { useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import { useNavigate } from "react-router-dom";
import { BsClipboard2Check } from "react-icons/bs";
import "./prescriptionRequest.css";

const PrescriptionRequest = ({ mdsData, pharmacyId }) => {
  const navigate = useNavigate();
  const today = new Date();
  const fiveDaysLater = addDays(today, 5);

  // Normalize today and the event date to midnight to avoid time zone issues
  const normalizeDate = (date) => {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized;
  };

  // Filter and sort prescription requests due in the next 5 days
  const upcomingRequests = useMemo(() => {
    const filteredRequests = mdsData.filter((mds) => {
      const startDate = normalizeDate(mds.start);
      return startDate >= normalizeDate(today) && startDate <= fiveDaysLater;
    });

    // Sort by date in ascending order
    return filteredRequests.sort(
      (a, b) => new Date(a.start) - new Date(b.start)
    );
  }, [mdsData, today, fiveDaysLater]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRequests = upcomingRequests.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(upcomingRequests.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  // Function to navigate to reminder-rx page and pass the mdsId
  const handleRequestPrescription = (mdsId) => {
    navigate(`/admin/${pharmacyId}/reminder-rx?mdsId=${mdsId}`);
  };

  // Determine background and label based on due date
  const getCardStylesAndLabel = (mdsDate) => {
    const startDate = normalizeDate(mdsDate);
    if (isToday(startDate)) {
      return {
        backgroundColor: "#ff5a2926",
        color: "#ff5a29",
        label: "Due Today",
      };
    } else if (isTomorrow(startDate)) {
      return {
        backgroundColor: "#0c9cfc26",
        color: "#0c9cfc",
        label: "Due Tomorrow",
      };
    } else {
      return {
        backgroundColor: "#ff9a1326",
        color: "#ff9a13",
        label: "Due in 3-5 Days",
      };
    }
  };

  return (
    <div className="prescription-requests-container">
      <h4 className="prescription-dashboard-title">
        Prescription Requests Due
      </h4>

      <div className="request-cards-container">
        {currentRequests.map((mds) => {
          const { backgroundColor, color, label } = getCardStylesAndLabel(
            mds.start
          );

          return (
            <div
              key={mds._id}
              className="request-card"
              style={{ backgroundColor, color }}
            >
              <div className="request-card-content">
                <div className="event-title">
                  {mds.patient?.firstName || "N/A"}{" "}
                  {mds.patient?.lastName || "N/A"}
                </div>
                <div className="event-time">
                  {format(new Date(mds.start), "dd/MM/yyyy")}
                </div>
                <div className="event-label">{label}</div>{" "}
                {/* Label below the date */}
              </div>
              <div className="request-card-action">
                <Button
                  size="sm"
                  variant="warning"
                  onClick={() => handleRequestPrescription(mds._id)}
                >
                  <BsClipboard2Check className="prescription-dashboard-icon" />
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      {/* Pagination */}
      <div className="prescription-dashboard-pagination-container">
        <Button
          className="prescription-dashboard-pagination-btn"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="prescription-dashboard-page-number">
          {currentPage} of {totalPages}
        </span>
        <Button
          className="prescription-dashboard-pagination-btn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default PrescriptionRequest;
