import React, { useEffect, useState } from "react";
import { fetchMdsByPharmacyAction } from "../../../redux/actions/mdsActions";
import { Button, Form, Modal, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import "./reminderRx.css";

export default function ReminderRx() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedMds, setSelectedMds] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [filterType, setFilterType] = useState("upcoming");
  const [selectedMedications, setSelectedMedications] = useState([]);

  const user = useSelector((state) => state.auth.user);
  const mdsData = useSelector((state) => state.mds.mdsState);

  useEffect(() => {
    dispatch(fetchMdsByPharmacyAction(pharmacyId)).catch((error) => {
      console.error("Error fetching pharmacy:", error);
    });
  }, [dispatch, pharmacyId]);

  // Extract mdsId from the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const mdsId = queryParams.get("mdsId");

    if (mdsId && mdsData.length > 0) {
      const mdsToOpen = mdsData.find((mds) => mds._id === mdsId);
      if (mdsToOpen) {
        setSelectedMds(mdsToOpen);
        setSelectedMedications(mdsToOpen.medicationDetails);
        setShowModal(true);
      }
    }
  }, [window.location.search, mdsData]);

  const handleOpenModal = (mds) => {
    setSelectedMds(mds);
    setSelectedMedications(mds.medicationDetails); // Default all selected
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedMds(null);
    setSelectedMedications([]);
    setShowModal(false);
  };

  const handleMedicationToggle = (medication) => {
    setSelectedMedications((prevSelected) =>
      prevSelected.includes(medication)
        ? prevSelected.filter((med) => med !== medication)
        : [...prevSelected, medication]
    );
  };

  const handleFilterMds = () => {
    const now = new Date();
    const today = new Date().toISOString().split("T")[0];
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowStr = tomorrow.toISOString().split("T")[0];

    let filteredData = mdsData;

    if (filterType === "today") {
      filteredData = filteredData.filter(
        (mds) => new Date(mds.start).toISOString().split("T")[0] === today
      );
    } else if (filterType === "tomorrow") {
      filteredData = filteredData.filter(
        (mds) => new Date(mds.start).toISOString().split("T")[0] === tomorrowStr
      );
    } else if (filterType === "upcoming") {
      filteredData = filteredData.filter((mds) => new Date(mds.start) >= now);
    } else if (filterType === "previous") {
      filteredData = filteredData.filter((mds) => new Date(mds.start) < now);
    }

    if (fromDate) {
      filteredData = filteredData.filter(
        (mds) => new Date(mds.start) >= new Date(fromDate)
      );
    }
    if (toDate) {
      filteredData = filteredData.filter(
        (mds) => new Date(mds.start) <= new Date(toDate)
      );
    }

    if (searchQuery) {
      filteredData = filteredData.filter((mds) => {
        const fullName =
          `${mds.patient.firstName} ${mds.patient.lastName}`.toLowerCase();
        return (
          fullName.includes(searchQuery.toLowerCase()) ||
          mds.patient.nhsNumber.toString().includes(searchQuery)
        );
      });
    }

    return filteredData;
  };

  const resetFilters = () => {
    setSearchQuery("");
    setFromDate("");
    setToDate("");
    setCurrentPage(1);
    setFilterType("upcoming");
  };

  const filteredData = handleFilterMds();

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMdsData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleNextPage = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1);
  const handlePrevPage = () =>
    currentPage > 1 && setCurrentPage(currentPage - 1);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page whenever items per page change
  };

  const handleCopyRequest = () => {
    if (selectedMds) {
      const selectedDetails = selectedMedications
        .map((detail) => `- ${detail.name} (${detail.dosage})`)
        .join("\n");

      const formattedDateOfBirth = new Date(
        selectedMds.patient.dateOfBirth
      ).toLocaleDateString("en-GB");

      const copyText = `Dear [Doctor's Name],\n\nRX Request for:\n\n${selectedMds.patient.title} ${selectedMds.patient.firstName} ${selectedMds.patient.lastName},\nDOB: ${formattedDateOfBirth}\n${selectedMds.patient.addressLine1}\n${selectedMds.patient.addressLine2}\n${selectedMds.patient.townName}\n${selectedMds.patient.postCode}\n\nMedications:\n${selectedDetails}\n\nThank you.`;

      navigator.clipboard.writeText(copyText);

      // Dispatch notification when copy request is clicked
      dispatch(
        createNotificationByPharmacyAction(pharmacyId, {
          content: `Prescription request copied for patient ${
            selectedMds.patient.firstName
          } ${
            selectedMds.patient.lastName
          } with selected medications: ${selectedDetails} on ${new Date().toLocaleDateString(
            "en-GB"
          )}.`,
          user: user._id,
          forRole: ["admin", "manager"],
          priority: "medium",
        })
      );
    }
    handleCloseModal();
  };

  return (
    <div className="reminder-rx-container">
      <div className="reminder-rx-search-input-box">
        <input
          type="text"
          placeholder="Search by name or NHS number..."
          className="reminder-rx-search-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="reminder-rx-header">
        <div>
          <Button
            className={
              filterType === "all"
                ? "reminder-rx-active-filter-btn"
                : "reminder-rx-reminder-btn"
            }
            onClick={() => setFilterType("all")}
          >
            All
          </Button>
          <Button
            className={
              filterType === "today"
                ? "reminder-rx-active-filter-btn"
                : "reminder-rx-reminder-btn"
            }
            onClick={() => setFilterType("today")}
          >
            Today
          </Button>
          <Button
            className={
              filterType === "tomorrow"
                ? "reminder-rx-active-filter-btn"
                : "reminder-rx-reminder-btn"
            }
            onClick={() => setFilterType("tomorrow")}
          >
            Tomorrow
          </Button>
          <Button
            className={
              filterType === "upcoming"
                ? "reminder-rx-active-filter-btn"
                : "reminder-rx-reminder-btn"
            }
            onClick={() => setFilterType("upcoming")}
          >
            Upcoming
          </Button>
          <Button
            className={
              filterType === "previous"
                ? "reminder-rx-active-filter-btn"
                : "reminder-rx-reminder-btn"
            }
            onClick={() => setFilterType("previous")}
          >
            Previous
          </Button>
        </div>
        <div className="reminder-rx-date-filter-box">
          <input
            type="date"
            className="reminder-rx-date-input"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <span className="reminder-rx-date-filter-text">to</span>
          <input
            type="date"
            className="reminder-rx-date-input"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />

          <Button
            className="reminder-rx-date-filter-button"
            onClick={resetFilters}
          >
            Reset Filters
          </Button>
        </div>
      </div>

      <div className="reminder-rx-items-per-page-selector">
        <select
          className="reminder-rx-form-select"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
        >
          {[5, 10, 15, 20].map((number) => (
            <option
              key={number}
              value={number}
              className="reminder-rx-items-per-page-option"
            >
              {number} Patients/page
            </option>
          ))}
        </select>
      </div>
      <div className="reminder-rx-table-container">
        <table bordered className="table table-bordered w-100">
          <thead>
            <tr>
              <th>Patient Name</th>
              <th>NHS Number</th>
              <th>Date</th>
              <th>Medication Name (Dosage)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentMdsData.map((mds) => (
              <tr key={mds._id}>
                <td>{`${mds.patient.firstName} ${mds.patient.lastName}`}</td>
                <td>{mds.patient.nhsNumber}</td>
                <td>
                  {new Date(mds.start).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  })}
                </td>
                <td>
                  {mds.medicationDetails
                    .map((detail) => `${detail.name} (${detail.dosage})`)
                    .join(", ")}
                </td>
                <td>
                  <Button size="sm" onClick={() => handleOpenModal(mds)}>
                    Request Prescription
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="page-number-box">
        {/* Pagination */}
        <div className="reminder-rx-pagination">
          <Button
            className="reminder-rx-pagination-btn"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="reminder-rx--page-number">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            className="reminder-rx--pagination-btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </div>

      {/* Modal for requesting prescription */}
      <Offcanvas
        show={showModal}
        onHide={handleCloseModal}
        placement="end"
        className="reminder-rx-offcanvas"
      >
        <Offcanvas.Header closeButton className="reminder-rx-offcanvas-header">
          <Offcanvas.Title className="reminder-rx-offcanvas-title">
            Request Prescription
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="reminder-rx-offcanvas-body">
          {selectedMds && (
            <div>
              <p>
                <b>Patient Name:</b> {selectedMds.patient.firstName}{" "}
                {selectedMds.patient.lastName}
              </p>
              <p>
                <b>Select Medications:</b>
              </p>
              <Form>
                {selectedMds.medicationDetails.map((medication, index) => (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    label={`${medication.name} (${medication.dosage})`}
                    checked={selectedMedications.includes(medication)}
                    onChange={() => handleMedicationToggle(medication)}
                  />
                ))}
              </Form>
              <p>
                <b>Request:</b>
                <br />
                Dear [Doctor's Name],
                <br />
                <br />
                RX Request for:
                <br />
                {selectedMds.patient.title} {selectedMds.patient.firstName}{" "}
                {selectedMds.patient.lastName},
                <br />
                DOB:{" "}
                {new Date(selectedMds.patient.dateOfBirth).toLocaleDateString(
                  "en-GB"
                )}
                <br />
                {selectedMds.patient.addressLine1}
                {selectedMds.patient.addressLine2}
                {selectedMds.patient.townName}
                {selectedMds.patient.postCode}
                <br />
                Medications:
                {selectedMedications
                  .map((detail) => `- ${detail.name} (${detail.dosage})`)
                  .join("\n")}
                <br />
                <br />
                Thank you.
              </p>
            </div>
          )}
        </Offcanvas.Body>
        <div className="reminder-rx-offcanvas-footer">
          <Button variant="primary" onClick={handleCopyRequest}>
            Copy Request
          </Button>
        </div>
      </Offcanvas>
    </div>
  );
}
