import React, { useMemo, useState } from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { format, getDay, getDate, subMonths } from "date-fns";
import "./mdsRepeatCycle.css";

// Helper function to get day of the week as a string
const getDayOfWeek = (date) => {
  const dayIndex = getDay(new Date(date)); // 0 is Sunday, 6 is Saturday
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[dayIndex];
};

// Helper function to get the day of the month
const getMonth = (date) => format(new Date(date), "MMM yyyy");

const MdsRepeatCycleGraph = ({ mdsRepeatCycles }) => {
  const [monthsToShow, setMonthsToShow] = useState(3); // Default to 3 months

  // Function to filter data by monthsToShow
  const filterDataByMonths = (data, months) => {
    const cutoffDate = subMonths(new Date(), months);
    return data.filter((cycle) => new Date(cycle.repeatUntil) >= cutoffDate);
  };

  // Prepare data for the bubble chart
  const data = useMemo(() => {
    const allCycles = [];
    const filteredCycles = filterDataByMonths(mdsRepeatCycles, monthsToShow);
    const dayCount = {}; // Track occurrences for each day across cycles

    filteredCycles.forEach((cycle) => {
      cycle.frequencyDates.forEach((dateObj) => {
        const dayOfWeek = getDayOfWeek(dateObj.start);
        const month = getMonth(dateObj.start);

        // Increment the count for each day (for bubble size)
        const key = `${month}-${dayOfWeek}`;
        if (!dayCount[key]) dayCount[key] = 0;
        dayCount[key]++;

        allCycles.push({
          month, // X-Axis (Month)
          dayOfWeek, // Y-Axis (Day of the week)
          count: dayCount[key], // Count to be used for relative bubble size
        });
      });
    });

    // Get the maximum count for scaling the bubble sizes
    const maxCount = Math.max(...Object.values(dayCount));

    // Normalize the size for bubbles
    return allCycles.map((cycle) => ({
      ...cycle,
      size: (cycle.count / maxCount) * 400, // Scale based on max count, adjust this for better visibility
    }));
  }, [mdsRepeatCycles, monthsToShow]);

  // Function to assign "active" class based on the current monthsToShow state
  const getButtonClass = (months) => {
    return monthsToShow === months ? "active-months" : "";
  };

  return (
    <div className="mds-repeat-cycle-container">
      <div className="cycle-dashboard-top-box">
        <div className="cycle-dashboard-title-box">
          <p className="cycle-dashboard-title">MDS Repeat Cycle</p>
          <p className="cycle-dashboard-para">
            Frequency of MDS repeat cycles covering which days are the busiest
          </p>
        </div>
        <div className="mdsGraphButtonBox">
          <button
            className={getButtonClass(3)}
            onClick={() => setMonthsToShow(3)}
          >
            3 Months
          </button>
          <button
            className={getButtonClass(6)}
            onClick={() => setMonthsToShow(6)}
          >
            6 Months
          </button>
          <button
            className={getButtonClass(12)}
            onClick={() => setMonthsToShow(12)}
          >
            12 Months
          </button>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart>
          <CartesianGrid />
          <XAxis
            type="category"
            dataKey="month"
            name="Month"
            allowDuplicatedCategory={false}
          />
          <YAxis
            type="category"
            dataKey="dayOfWeek"
            name="Day of Week"
            domain={[
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ]}
          />
          <ZAxis
            type="number"
            dataKey="size"
            range={[100, 400]}
            name="Cycle Count"
          />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
          <Scatter data={data} fill="#8884d8" />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MdsRepeatCycleGraph;
