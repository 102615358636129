import React, { useMemo, useState, useCallback } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { format, subMonths } from "date-fns";
import "./collectionGraph.css";

const COLORS_HANDOUT = ["#ffb300", "#0cc763"]; // Colors for handout (Pending, Collected)
const COLORS_DELIVERY = ["#ffb300", "#735dff"]; // Colors for delivery (Pending, Picked-Up)

const CollectionGraph = ({ collections }) => {
  const [monthsToShow, setMonthsToShow] = useState(3);
  const [highlightedArea, setHighlightedArea] = useState(null); // State to track highlighted area

  const getMonths = (monthsBack) => {
    let months = [];
    let currentDate = new Date();

    for (let i = monthsBack - 1; i >= 0; i--) {
      let pastDate = subMonths(currentDate, i);
      months.push(format(pastDate, "MMM yyyy"));
    }

    return months;
  };

  // Prepare area chart data
  const areaData = useMemo(() => {
    const months = getMonths(monthsToShow);
    const initialData = months.map((month) => ({
      month,
      handout: 0,
      delivery: 0,
    }));

    collections.forEach((collection) => {
      const collectionMonthYear = format(
        new Date(collection.createdAt),
        "MMM yyyy"
      );
      const dataIndex = initialData.findIndex(
        (data) => data.month === collectionMonthYear
      );

      if (dataIndex !== -1) {
        if (collection.collectionType === "handout")
          initialData[dataIndex].handout++;
        else if (collection.collectionType === "delivery")
          initialData[dataIndex].delivery++;
      }
    });

    return initialData;
  }, [collections, monthsToShow]);

  // Prepare pie chart data for handouts and deliveries
  const pieDataHandout = useMemo(() => {
    const pending = collections.filter(
      (collection) =>
        collection.collectionType === "handout" &&
        collection.status === "Pending"
    ).length;
    const collected = collections.filter(
      (collection) =>
        collection.collectionType === "handout" &&
        collection.status === "Collected"
    ).length;
    return [
      { name: "Pending", value: pending },
      { name: "Collected", value: collected },
    ];
  }, [collections]);

  const pieDataDelivery = useMemo(() => {
    const pending = collections.filter(
      (collection) =>
        collection.collectionType === "delivery" &&
        collection.status === "Pending"
    ).length;
    const pickedUp = collections.filter(
      (collection) =>
        collection.collectionType === "delivery" &&
        collection.status === "Picked-Up"
    ).length;
    return [
      { name: "Pending", value: pending },
      { name: "Picked-Up", value: pickedUp },
    ];
  }, [collections]);

  // Function to assign "active" class based on the current monthsToShow state
  const getButtonClass = (months) => {
    return monthsToShow === months ? "active-months" : "";
  };

  const handleLegendMouseEnter = useCallback((o) => {
    setHighlightedArea(o.dataKey);
  }, []);

  const handleLegendMouseLeave = useCallback(() => {
    setHighlightedArea(null);
  }, []);

  return (
    <div className="pie-graphs-container">
      <div className="collection-dashboard-title">Collections</div>
      <div className="pie-title-box">
        <div className="dashboard-pie-title">Order Statistics : </div>
        <div className="pie-title-box">
          <button
            className={getButtonClass(3)}
            onClick={() => setMonthsToShow(3)}
          >
            3 Months
          </button>
          <button
            className={getButtonClass(6)}
            onClick={() => setMonthsToShow(6)}
          >
            6 Months
          </button>
          <button
            className={getButtonClass(12)}
            onClick={() => setMonthsToShow(12)}
          >
            12 Months
          </button>
        </div>
      </div>

      {/* Area Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={areaData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <Tooltip />
          <Legend
            onMouseEnter={handleLegendMouseEnter}
            onMouseLeave={handleLegendMouseLeave}
            align="left"
            verticalAlign="top"
            wrapperStyle={{
              paddingLeft: 0,
              transform: "translateY(-35px)",
              maxWidth: "200px",
            }}
            formatter={(value, entry, index) => (
              <span
                style={{
                  fontSize: "1rem",
                  fontFamily: "Figtree-SemiBold",
                  marginRight: "10px",
                  textTransform: "capitalize",
                  textShadow: "1px 1px 1px #fff",
                  cursor: "pointer",
                }}
              >
                {value}
              </span>
            )}
          />
          <Area
            type="monotone"
            dataKey="handout"
            stackId="1"
            stroke="#735dff"
            strokeWidth={2}
            fill="#8884d8"
            opacity={highlightedArea && highlightedArea !== "handout" ? 0.2 : 1}
          />
          <Area
            type="monotone"
            dataKey="delivery"
            stackId="1"
            strokeWidth={2}
            stroke="#0cc763"
            fill="#0cc76326"
            opacity={
              highlightedArea && highlightedArea !== "delivery" ? 0.2 : 1
            }
          />
        </AreaChart>
      </ResponsiveContainer>

      {/* Pie Charts */}
      <div className="handout-delivery-pie-box">
        {/* Handout Pie Chart */}
        <div className="handout-pie-box">
          <h4 className="handout-pie-title">Handouts Status</h4>
          <ResponsiveContainer
            width={200}
            height={220}
            className="handout-pie-container"
          >
            <PieChart>
              <Pie
                data={pieDataHandout}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label
              >
                {pieDataHandout.map((entry, index) => (
                  <Cell
                    key={`cell-handout-${index}`}
                    fill={COLORS_HANDOUT[index % COLORS_HANDOUT.length]}
                  />
                ))}
              </Pie>
              <Legend
                align="left"
                verticalAlign="top"
                wrapperStyle={{
                  paddingLeft: 0,
                  transform: "translateY(-35px)",
                  maxWidth: "200px",
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Delivery Pie Chart */}
        <div className="delivery-pie-box">
          <h4 className="delivery-pie-title">Delivery Status</h4>
          <ResponsiveContainer
            width={200}
            height={220}
            className="delivery-pie-container"
          >
            <PieChart>
              <Pie
                data={pieDataDelivery}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#82ca9d"
                dataKey="value"
                label
              >
                {pieDataDelivery.map((entry, index) => (
                  <Cell
                    key={`cell-delivery-${index}`}
                    fill={COLORS_DELIVERY[index % COLORS_DELIVERY.length]}
                  />
                ))}
              </Pie>
              <Legend
                align="left"
                verticalAlign="top"
                wrapperStyle={{
                  paddingLeft: 0,
                  transform: "translateY(-35px)",
                  maxWidth: "200px",
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default CollectionGraph;
