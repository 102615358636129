import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { IoTrendingUp, IoTrendingDown } from "react-icons/io5";

import "./mixgraph.css";

function ShelveMixBarGraph({ shelves }) {
  const isUpdatedThisWeek = (updatedAt) => {
    const today = new Date();
    const updatedDate = new Date(updatedAt);
    const timeDiff = Math.abs(today - updatedDate);
    const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return dayDiff <= 7; // within the past 7 days
  };

  // Prepare chart data
  const data = shelves.map((shelf) => ({
    name: shelf.name,
    TotalCapacity: shelf.shelfCapacity,
    OccupiedCapacity: shelf.occupiedCapacity,
    AvailableCapacity: shelf.shelfCapacity - shelf.occupiedCapacity,
  }));

  return (
    <div className="mix-graph-container">
      <div className="shelf-dashboard-title">Shelves</div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend
            align="left" /* Aligns the legend to the left */
            verticalAlign="top" /* Positions the legend at the top */
            wrapperStyle={{
              paddingLeft: 0,
              transform: "translateY(-35px)",
              maxWidth: "200px",
            }}
          />
          <Bar dataKey="TotalCapacity" fill="#0c9cfc" name="Total Capacity" />
          <Bar
            dataKey="OccupiedCapacity"
            fill="#0cc763"
            name="Occupied Capacity"
          />
        </BarChart>
      </ResponsiveContainer>
      <div className="shelves-percentage-comparison-box">
        {shelves.map((shelf) => (
          <div className="shelf-percentage-comparison" key={shelf._id}>
            <div className="shelf-percentage-comparison-left">
              <div className="shelf-name">{shelf.name}</div>
              <div className="percentage-box">
                <div
                  className={`shelf-percentage-value ${
                    isUpdatedThisWeek(shelf.updatedAt)
                      ? "updated"
                      : "not-updated"
                  }`}
                >
                  {(
                    (shelf.occupiedCapacity / shelf.shelfCapacity) *
                    100
                  ).toFixed(2)}
                  %{" "}
                  {isUpdatedThisWeek(shelf.updatedAt) ? (
                    <IoTrendingUp className="trend-icon" />
                  ) : (
                    <IoTrendingDown className="trend-icon" />
                  )}
                </div>
              </div>
            </div>
            <div className="shelf-percentage-comparison-right">
              {shelf.occupiedCapacity} / {shelf.shelfCapacity}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ShelveMixBarGraph;
