import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import {
  markNotificationAsRead,
  fetchNotificationsByPharmacyAction,
} from "../../redux/actions/notificationActions";
import { FcHighPriority, FcMediumPriority } from "react-icons/fc";
import { BsArrowRightShort } from "react-icons/bs";
import { HiOutlineBellAlert } from "react-icons/hi2";
import "./notification.css";

const NotificationDropdown = () => {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const user = useSelector((state) => state.auth.user);

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const closeDropdownTimeout = useRef(null);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [recentNotifications, setRecentNotifications] = useState([]);

  useEffect(() => {
    const filteredUnread = notifications.filter(
      (notification) => !notification.isRead
    );
    const recent = [...notifications]
      .filter((notification) => !notification.isRead)
      .reverse()
      .slice(0, 5);
    setUnreadNotifications(filteredUnread);
    setRecentNotifications(recent);
  }, [notifications]);

  const handleMouseEnter = () => {
    if (closeDropdownTimeout.current) {
      clearTimeout(closeDropdownTimeout.current);
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    closeDropdownTimeout.current = setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  const handleMarkAsRead = async (notificationId) => {
    await dispatch(markNotificationAsRead(pharmacyId, notificationId));
    // After marking as read, update the local state
    setRecentNotifications((prev) =>
      prev.filter((notification) => notification._id !== notificationId)
    );
    setUnreadNotifications((prev) =>
      prev.filter((notification) => notification._id !== notificationId)
    );
  };

  useEffect(() => {
    dispatch(fetchNotificationsByPharmacyAction(pharmacyId));
    return () => clearTimeout(closeDropdownTimeout.current);
  }, [pharmacyId, dispatch]);

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={dropdownRef}
    >
      <button
        className={`header-notification-btn ${
          unreadNotifications.length > 0 ? "wiggle" : ""
        }`}
      >
        <HiOutlineBellAlert />
        {unreadNotifications.length > 0 && (
          <span className="notification-dot" />
        )}
      </button>
      {isOpen && (
        <div className="notification-menu">
          <div className="notification-header">
            <p className="notification-header-text">Notifications</p>
            <div className="notification-header-count-container">
              <p className="notification-header-count">
                {unreadNotifications.length} unread
              </p>
            </div>
          </div>
          {recentNotifications.length > 0 ? (
            recentNotifications.map((notification) => (
              <div key={notification._id} className="notification-item">
                {notification.priority === "high" ? (
                  <FcHighPriority className="notification-priority-icon" />
                ) : (
                  <FcMediumPriority className="notification-priority-icon" />
                )}
                <div className="notifications-item-content">
                  <p className="notification-text">{notification.content}</p>
                  <span className="notification-time">
                    {new Date(notification.createdAt).toLocaleString()}
                  </span>
                </div>
                <button
                  className="tick-btn"
                  onClick={() => handleMarkAsRead(notification._id)}
                >
                  <FaCheck className="notification-tick-icon" />
                </button>
              </div>
            ))
          ) : (
            <p className="notification-empty">No new notifications</p>
          )}
          <NavLink
            to={`/admin/${user.pharmacy}/notifications`}
            className="view-all-btn"
          >
            <p className="view-all-text">View All</p>
            <BsArrowRightShort className="view-all-icon" />
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default NotificationDropdown;
