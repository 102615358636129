import React, { useState, useMemo } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Table, Button } from "react-bootstrap";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import isToday from "date-fns/isToday";
import "./dashboardCalendar.css";

const DashboardCalendar = ({ appointments }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Setup localizer for react-big-calendar
  const locales = {
    "en-US": enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  // Custom Event Component
  const CustomEvent = ({ event }) => {
    return (
      <div className="event-circle">{event.title.charAt(0).toUpperCase()}</div>
    );
  };

  // Filter today's events
  const todaysEvents = useMemo(() => {
    return appointments.filter((appointment) =>
      isToday(new Date(appointment.start))
    );
  }, [appointments]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 3;

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = todaysEvents.slice(indexOfFirstEvent, indexOfLastEvent);

  const totalPages = Math.ceil(todaysEvents.length / eventsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  // Convert appointments data to events
  const events = appointments.map((appointment) => ({
    title: appointment.title,
    start: new Date(appointment.start),
    end: new Date(appointment.end),
  }));

  return (
    <div className="dashboard-calendar-container">
      <div className="calendar-title-box">
        <h4 className="calendar-dashboard-title">Today's Appointments</h4>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 300 }} // Adjust the height to make it small
            defaultView="month"
            views={["month"]} // Only allow the "month" view
            toolbar={false} // Disable toolbar (no navigation)
            popup
            components={{
              event: CustomEvent, // Use the custom event component
            }}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="table-box">
            <Table className="table w-100">
              <tbody className="calendar-dashboard-table-body">
                {currentEvents.map((event) => (
                  <tr key={event._id}>
                    <td>
                      <div className="event-title">{event.title}</div>
                      <div className="event-time">
                        {format(new Date(event.start), "p")} -{" "}
                        {format(new Date(event.end), "p")}
                      </div>
                      <div className="event-details">
                        With: {event.appointmentWith}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {todaysEvents.length > eventsPerPage && (
        <div className="calendar-pagination-box">
          <Button
            className="calendar-pagination-btn"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="calendar-page-number">
            {currentPage} of {totalPages}
          </span>
          <Button
            className="calendar-pagination-btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default DashboardCalendar;
