import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Offcanvas } from "react-bootstrap";
import { FiEdit, FiTrash, FiMail } from "react-icons/fi"; // Import icons
import { sendTeamInviteActionByPharmacy } from "../../../redux/actions/authActions";
import {
  fetchMembersByPharmacyAction,
  updateMemberByPharmacyAction,
  deleteMemberByPharmacyAction,
} from "../../../redux/actions/memberAction";
import { useParams } from "react-router-dom";
import Overlay from "../../../Components/Overlay";
import "./createMember.css";

function CreateMember() {
  const { pharmacyId } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("manager");
  const [roleType, setRoleType] = useState("");
  const [currentMemberId, setCurrentMemberId] = useState(null);
  const [currentMemberRole, setCurrentMemberRole] = useState("");
  const [currentMemberRoleStatus, setCurrentMemberRoleStatus] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [memberOffcanvasShow, setMemberOffcanvasShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const membersPerPage = 5;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleMemberClose = () => setMemberOffcanvasShow(false);

  const user = useSelector((state) => state.auth.user);
  const userId = user._id;
  const userCurrentPlan = user.currentPlan;
  const members = useSelector((state) => state.member.members);

  const handleTeamInvite = () => {
    const inviteData = { email, role, adminUserId: userId, roleType };
    dispatch(sendTeamInviteActionByPharmacy(pharmacyId, inviteData));
    clearFields();
    handleClose();
  };

  const clearFields = () => {
    setEmail("");
    setRole("manager");
    setRoleType("");
  };

  const handleMemberShow = (member) => () => {
    setCurrentMemberId(member._id);
    setCurrentMemberRole(member.role);
    setCurrentMemberRoleStatus(member.roleStatus);
    setMemberOffcanvasShow(true);
  };

  const updateMemberDetails = async () => {
    dispatch(
      updateMemberByPharmacyAction(pharmacyId, currentMemberId, {
        role: currentMemberRole,
        roleStatus: currentMemberRoleStatus,
      })
    );
    handleMemberClose();
    fetchMembers();
  };

  const fetchMembers = () => {
    dispatch(fetchMembersByPharmacyAction(pharmacyId));
  };

  const showDeleteModal = (memberId) => () => {
    setMemberToDelete(memberId);
    setShowDeleteConfirm(true);
  };

  const confirmDeleteMember = () => {
    if (memberToDelete) {
      dispatch(deleteMemberByPharmacyAction(pharmacyId, memberToDelete));
      setMemberToDelete(null);
      setShowDeleteConfirm(false);
      fetchMembers();
    }
  };

  const indexOfLastMember = currentPage * membersPerPage;
  const indexOfFirstMember = indexOfLastMember - membersPerPage;
  const currentMembers = members.slice(indexOfFirstMember, indexOfLastMember);
  const totalPages = Math.ceil(members.length / membersPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      } else {
        document.body.classList.remove("no-interaction");
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [userCurrentPlan]);

  useEffect(() => {
    const handleClicks = () => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      }
    };

    document.addEventListener("click", handleClicks);

    return () => document.removeEventListener("click", handleClicks);
  }, [userCurrentPlan]);

  return (
    <>
      <div className="createMember-container">
        {userCurrentPlan === "Free" && <Overlay />}
        <div className="createMember-header">
          <button className="createMember-invite-btn" onClick={handleShow}>
            <FiMail className="createMember-invite-icon" />
            Invite Team Member
          </button>

          {/* Legends */}
          <div className="createMember-legends">
            <div className="createMember-legend-item">
              <FiEdit className="createMember-legend-icon createMember-legend-update" />
              <span className="createMember-legend-text">Update</span>
            </div>
            <div className="createMember-legend-item">
              <FiTrash className="createMember-legend-icon createMember-legend-delete" />
              <span className="createMember-legend-text">Delete</span>
            </div>
          </div>
        </div>

        <div className="createMember-table-container">
          <table className="table table-bordered w-100">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Mail Status</th>
                <th>Role Status</th>
                <th>Update</th>
                <th>Delete</th>
              </tr>
            </thead>
            {currentMembers &&
              currentMembers.map((member) => (
                <tbody className="align-items-center" key={member._id}>
                  <tr>
                    <td>{member?.name}</td>
                    <td>{member?.email}</td>
                    <td>
                      {member?.role.charAt(0).toUpperCase() +
                        member?.role.slice(1)}
                    </td>
                    <td>
                      {member?.status.charAt(0).toUpperCase() +
                        member?.status.slice(1)}
                    </td>
                    <td>
                      {member?.roleStatus.charAt(0).toUpperCase() +
                        member?.roleStatus.slice(1)}
                    </td>
                    <td>
                      <FiEdit
                        className="createMember-update-icon"
                        onClick={handleMemberShow(member)}
                      />
                    </td>
                    <td>
                      <FiTrash
                        className="createMember-delete-icon"
                        onClick={showDeleteModal(member._id)}
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>

        {/* Pagination */}
        <div className="createMember-pagination">
          <Button
            className="createMember-pagination-btn"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="createMember-page-number">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            className="createMember-pagination-btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </div>
      {/* Invite Offcanvas */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="createMember-offcanvas"
      >
        <Offcanvas.Header closeButton className="createMember-offcanvas-header">
          <Offcanvas.Title>Invite Team Member</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="createMember-offcanvas-body">
          <Form>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formRole" className="mb-3">
              <Form.Label>Role Category</Form.Label>
              <Form.Select
                aria-label="Select Role Category"
                onChange={(e) => setRole(e.target.value)}
                value={role}
              >
                <option value="manager">Manager</option>
                <option value="driver">Driver</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formRoleType" className="mb-3">
              <Form.Label>Role Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Purpose: Assistant / Pharmacist"
                value={roleType}
                onChange={(e) => setRoleType(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Offcanvas.Body>
        <div className="createMember-offcanvas-footer">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleTeamInvite();
              handleClose();
            }}
          >
            Send Invite
          </Button>
        </div>
      </Offcanvas>

      {/* Update Member Offcanvas */}
      <Offcanvas
        show={memberOffcanvasShow}
        onHide={handleMemberClose}
        placement="end"
        className="createMember-offcanvas"
      >
        <Offcanvas.Header closeButton className="createMember-offcanvas-header">
          <Offcanvas.Title>Update Member</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="createMember-offcanvas-body">
          <Form>
            <Form.Group controlId="updateRole" className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                value={currentMemberRole}
                onChange={(e) => setCurrentMemberRole(e.target.value)}
              >
                <option value="">Select Role</option>
                <option value="manager">Manager</option>
                <option value="driver">Driver</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="updateStatus" className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={currentMemberRoleStatus}
                onChange={(e) => setCurrentMemberRoleStatus(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="suspended">Suspended</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
        <div className="createMember-offcanvas-footer">
          <Button variant="secondary" onClick={handleMemberClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateMemberDetails}>
            Update
          </Button>
        </div>
      </Offcanvas>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this member?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirm(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteMember}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateMember;
