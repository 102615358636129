import React, { useEffect, useState, useRef } from "react";
import { Button, Form, Table, Offcanvas, Modal } from "react-bootstrap";
import { FiEdit, FiTrash, FiEye, FiHome, FiUsers } from "react-icons/fi";
import {
  createCareHomeByPharmacyAction,
  deleteCareHomeByPharmacyAction,
  fetchCareHomesByPharmacyAction,
  updateCareHomeByPharmacyAction,
} from "../../../redux/actions/careHomeActions";
import { fetchPatientsByPharmacyAction } from "../../../redux/actions/patientAction";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  WarningNotification,
  ErrorNotification,
} from "../../../Components/Notifications/ToastNotifications";
import "./careHome.css";

const defaultDetails = {
  name: "",
  address: "",
  postCode: "",
  landline: "",
  mobile: "",
  note: "",
};

const defaultManager = {
  name: "",
  landline: "",
  mobile: "",
};

const defaultCarer = {
  name: "",
  landline: "",
  mobile: "",
};

const CareHome = () => {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [newDetails, setNewDetails] = useState(defaultDetails);
  const [managerDetails, setManagerDetails] = useState(defaultManager);
  const [carerDetails, setCarerDetails] = useState(defaultCarer);
  const [mode, setMode] = useState("add");
  const [selectedCareHome, setSelectedCareHome] = useState({});
  const [patientsUnderCareHome, setPatientsUnderCareHome] = useState([]);
  const [patientsOffcanvasShow, setPatientsOffcanvasShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [careHomeToDelete, setCareHomeToDelete] = useState(null);
  const [careHomeDetailsOffcanvasShow, setCareHomeDetailsOffcanvasShow] =
    useState(false);
  const [careHomeOffcanvasShow, setCareHomeOffcanvasShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const careHomes = useSelector((state) => state.careHome.careHomes);
  const user = useSelector((state) => state.auth.user);
  const patients = useSelector((state) => state.patient.patients);

  const componentRef = useRef();
  const handleSave = () => {
    if (
      !newDetails.name ||
      !newDetails.address ||
      (!newDetails.landline && !newDetails.mobile)
    ) {
      WarningNotification(
        "Please provide a name, address, and at least one phone number (mobile or landline)."
      );
      return;
    }

    const careHomeData = {
      ...newDetails,
      managerName: managerDetails.name,
      managerLandline: managerDetails.landline,
      managerMobile: managerDetails.mobile,
      careDetailsName: carerDetails.name,
      careDetailsLandline: carerDetails.landline,
      careDetailsMobile: carerDetails.mobile,
    };

    if (mode === "add") {
      // No ID is needed for creating a new care home
      dispatch(createCareHomeByPharmacyAction(pharmacyId, careHomeData))
        .then(() => {
          // Success logic here
          handleSuccess();
        })
        .catch((error) => {
          ErrorNotification(error);
        });
    } else if (mode === "edit") {
      // Ensure that ID is included for updating
      dispatch(
        updateCareHomeByPharmacyAction(pharmacyId, newDetails.id, careHomeData)
      )
        .then(() => {
          // Success logic here
          handleSuccess();
        })
        .catch((error) => {
          ErrorNotification(error);
        });
    }
  };

  const handleSuccess = () => {
    setCareHomeOffcanvasShow(false);
    setNewDetails(defaultDetails);
    setManagerDetails(defaultManager);
    setCarerDetails(defaultCarer);
    getCareHomes();
    dispatch(
      createNotificationByPharmacyAction(pharmacyId, {
        content: `Care home ${mode === "add" ? "created" : "updated"}: ${
          newDetails.name
        }`,
        user: user._id,
        forRole: ["admin", "manager"],
        priority: mode === "add" ? "high" : "medium",
      })
    );
  };

  const deleteCareHomeHandler = (careHomeId) => {
    setCareHomeToDelete(careHomeId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (careHomeToDelete) {
      deleteCareHome(careHomeToDelete);
      setShowDeleteModal(false);
      setCareHomeToDelete(null);
    }
  };
  const deleteCareHome = (id) => {
    dispatch(deleteCareHomeByPharmacyAction(pharmacyId, id))
      .then(() => {
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Care home deleted.`,
            user: user._id,
            forRole: ["admin"],
            priority: "high",
          })
        );
        getCareHomes();
      })
      .catch(() => {
        ErrorNotification("Error deleting care home.");
      });
  };

  const handleChange = (e, detailsType) => {
    const { name, value } = e.target;
    if (["landline", "mobile"].includes(name) && value.length > 12) return;
    const setter =
      detailsType === "newDetails"
        ? setNewDetails
        : detailsType === "managerDetails"
        ? setManagerDetails
        : setCarerDetails;
    setter((prev) => ({ ...prev, [name]: value }));
  };

  const getCareHomes = () => {
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId));
  };

  const getPatients = () => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId)).catch((err) => {
      console.error("Error fetching patients:", err);
    });
  };

  useEffect(() => {
    getPatients();
    getCareHomes();
  }, []);

  const openOffcanvas = (careHome = null) => {
    if (careHome) {
      setNewDetails({
        id: careHome._id, // Ensure this is correct as per your data structure
        ...careHome,
      });
      // Assuming manager and carer details are part of careHome, adjust accordingly
      setManagerDetails({
        name: careHome.managerName,
        landline: careHome.managerLandline,
        mobile: careHome.managerMobile,
      });
      setCarerDetails({
        name: careHome.careDetailsName,
        landline: careHome.careDetailsLandline,
        mobile: careHome.careDetailsMobile,
      });
      setMode("edit");
    } else {
      setNewDetails(defaultDetails);
      setManagerDetails(defaultManager);
      setCarerDetails(defaultCarer);
      setMode("add");
    }
    setCareHomeOffcanvasShow(true);
  };

  const closeOffcanvas = () => {
    setCareHomeOffcanvasShow(false);
    setNewDetails(defaultDetails);
    setManagerDetails(defaultManager);
    setCarerDetails(defaultCarer);
  };

  const viewCareHome = (careHome) => {
    setSelectedCareHome(careHome);
    setCareHomeDetailsOffcanvasShow(true);
  };

  const closeViewOffcanvas = () => {
    setCareHomeDetailsOffcanvasShow(false);
  };

  const viewPatientsUnderCareHome = (careHomeId) => {
    const filteredPatients = patients.filter(
      (patient) => patient.careHome === careHomeId
    );
    setPatientsUnderCareHome(filteredPatients);
    setPatientsOffcanvasShow(true);
  };

  const closePatientsOffcanvas = () => {
    setPatientsOffcanvasShow(false);
    setPatientsUnderCareHome([]);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCareHomes = careHomes.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(careHomes.length / itemsPerPage);

  const handleNextPage = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1);
  const handlePrevPage = () =>
    currentPage > 1 && setCurrentPage(currentPage - 1);

  return (
    <>
      <div className="careHome-container">
        <div className="careHome-header">
          <button
            onClick={() => openOffcanvas()}
            className="careHome-invite-btn"
          >
            <FiHome className="careHome-invite-icon" />
            Create Care Home
          </button>

          {/* Legends */}
          <div className="careHome-legends">
            <div className="careHome-legend-item">
              <FiUsers className="careHome-legend-icon careHome-legend-patients" />
              <span className="careHome-legend-text">
                View Patients Under Carehome
              </span>
            </div>
            <div className="careHome-legend-item">
              <FiEye className="careHome-legend-icon careHome-legend-view" />
              <span className="careHome-legend-text">View</span>
            </div>
            <div className="careHome-legend-item">
              <FiEdit className="careHome-legend-icon careHome-legend-update" />
              <span className="careHome-legend-text">Update</span>
            </div>
            <div className="careHome-legend-item">
              <FiTrash className="careHome-legend-icon careHome-legend-delete" />
              <span className="careHome-legend-text">Delete</span>
            </div>
          </div>
        </div>

        <div className="careHome-table-container">
          <table className="table table-bordered w-100">
            <thead>
              <tr>
                <th>Name</th>
                <th>Manager</th>
                <th>Carer</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentCareHomes.map((careHome) => (
                <tr key={careHome._id}>
                  <td>{careHome.name}</td>
                  <td>{careHome.managerName}</td>
                  <td>{careHome.careDetailsName}</td>
                  <td>
                    <FiUsers
                      className="careHome-action-icon careHome-view-patients-icon"
                      onClick={() => viewPatientsUnderCareHome(careHome._id)}
                    />
                    <FiEye
                      className="careHome-action-icon careHome-view-icon"
                      onClick={() => viewCareHome(careHome)}
                    />
                    <FiEdit
                      className="careHome-action-icon careHome-edit-icon"
                      onClick={() => openOffcanvas(careHome)}
                    />
                    <FiTrash
                      className="careHome-action-icon careHome-delete-icon"
                      onClick={() => deleteCareHomeHandler(careHome._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="careHome-pagination">
          <Button
            className="careHome-pagination-btn"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="careHome-page-number">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            className="careHome-pagination-btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </div>

      {/* Create/Edit Care Home Offcanvas */}
      <Offcanvas
        show={careHomeOffcanvasShow}
        onHide={closeOffcanvas}
        placement="end"
        className="careHome-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {mode === "add" ? "Create Care Home" : "Edit Care Home"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="careHome-offcanvas-body">
          <Form>
            <div className="row">
              <h4 style={{ padding: "0 0.75rem" }}>Care Home Details</h4>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupName"
              >
                <Form.Label>
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  value={newDetails.name}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupAddress"
              >
                <Form.Label>
                  Address <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  required
                  value={newDetails.address}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupPostCode"
              >
                <Form.Label>Post Code</Form.Label>
                <Form.Control
                  type="text"
                  name="postCode"
                  value={newDetails.postCode}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupLandline"
              >
                <Form.Label>
                  Landline <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="landline"
                  required
                  value={newDetails.landline}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupMobile"
              >
                <Form.Label>
                  Mobile <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  required
                  value={newDetails.mobile}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupNote"
              >
                <Form.Label>Note</Form.Label>
                <Form.Control
                  type="text"
                  name="note"
                  value={newDetails.note}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
            </div>
            <div className="row">
              <h4 style={{ padding: "0 0.75rem" }}>Manager Details</h4>
              <Form.Group
                className="col-xs-12"
                controlId="formGroupManagerName"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  value={managerDetails.name}
                  onChange={(e) => handleChange(e, "managerDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-xs-12"
                controlId="formGroupManagerLandline"
              >
                <Form.Label>Landline</Form.Label>
                <Form.Control
                  type="text"
                  name="landline"
                  required
                  value={managerDetails.landline}
                  onChange={(e) => handleChange(e, "managerDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-xs-12"
                controlId="formGroupManagerMobile"
              >
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  required
                  value={managerDetails.mobile}
                  onChange={(e) => handleChange(e, "managerDetails")}
                />
              </Form.Group>
              <h4 style={{ padding: "0 0.75rem" }}>Carer Details</h4>
              <Form.Group className="col-xs-12" controlId="formGroupCarerName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  value={carerDetails.name}
                  onChange={(e) => handleChange(e, "carerDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-xs-12"
                controlId="formGroupCarerLandline"
              >
                <Form.Label>Landline</Form.Label>
                <Form.Control
                  type="text"
                  name="landline"
                  required
                  value={carerDetails.landline}
                  onChange={(e) => handleChange(e, "carerDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-xs-12"
                controlId="formGroupCarerMobile"
              >
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  required
                  value={carerDetails.mobile}
                  onChange={(e) => handleChange(e, "carerDetails")}
                />
              </Form.Group>
            </div>
          </Form>
        </Offcanvas.Body>
        <div className="careHome-offcanvas-footer">
          <Button variant="secondary" onClick={closeOffcanvas}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </Offcanvas>

      {/* View Care Home Offcanvas */}
      <Offcanvas
        show={careHomeDetailsOffcanvasShow}
        onHide={closeViewOffcanvas}
        placement="end"
        className="careHome-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Care Home Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="careHome-offcanvas-body">
          <div className="careHome-details-section">
            <h4 className="careHome-offcanvas-subtitle">
              Care Home Information
            </h4>
            <div className="careHome-detail-item">
              <strong>Name:</strong> {selectedCareHome.name}
            </div>
            <div className="careHome-detail-item">
              <strong>Address:</strong> {selectedCareHome.address}
            </div>
            <div className="careHome-detail-item">
              <strong>Post Code:</strong> {selectedCareHome.postCode}
            </div>
            <div className="careHome-detail-item">
              <strong>Landline:</strong> {selectedCareHome.landline}
            </div>
            <div className="careHome-detail-item">
              <strong>Mobile:</strong> {selectedCareHome.mobile}
            </div>
            <div className="careHome-detail-item">
              <strong>Note:</strong> {selectedCareHome.note}
            </div>
          </div>

          <div className="careHome-details-section">
            <h4 className="careHome-offcanvas-subtitle">Manager Information</h4>
            <div className="careHome-detail-item">
              <strong>Name:</strong> {selectedCareHome.managerName}
            </div>
            <div className="careHome-detail-item">
              <strong>Contact:</strong> {selectedCareHome.managerLandline},{" "}
              {selectedCareHome.managerMobile}
            </div>
          </div>

          <div className="careHome-details-section">
            <h4 className="careHome-offcanvas-subtitle">Carer Information</h4>
            <div className="careHome-detail-item">
              <strong>Name:</strong> {selectedCareHome.careDetailsName}
            </div>
            <div className="careHome-detail-item">
              <strong>Contact:</strong> {selectedCareHome.careDetailsLandline},{" "}
              {selectedCareHome.careDetailsMobile}
            </div>
          </div>
        </Offcanvas.Body>
        <div className="careHome-offcanvas-footer">
          <Button variant="secondary" onClick={closeViewOffcanvas}>
            Close
          </Button>
        </div>
      </Offcanvas>

      {/* View Patients Under Care Home Offcanvas */}
      <Offcanvas
        show={patientsOffcanvasShow}
        onHide={closePatientsOffcanvas}
        placement="end"
        className="careHome-offcanvas"
      >
        <Offcanvas.Header closeButton className="careHome-offcanvas-header">
          <Offcanvas.Title>Patients Under Care Home</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="careHome-offcanvas-body">
          {patientsUnderCareHome.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>NHS Number</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              <tbody>
                {patientsUnderCareHome.map((patient, index) => (
                  <tr key={patient._id}>
                    <td>{index + 1}</td>
                    <td>{patient.displayName}</td>
                    <td>{patient.nhsNumber}</td>
                    <td>
                      {new Date(patient.createdAt).toLocaleDateString("en-GB")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No patients have been added under this care home.</p>
          )}
        </Offcanvas.Body>
        <div className="careHome-offcanvas-footer">
          <Button variant="secondary" onClick={closePatientsOffcanvas}>
            Close
          </Button>
        </div>
      </Offcanvas>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
        className="careHome-delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this care home? This action cannot
            be undone.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CareHome;
