import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {
  createShelveByPharmacyAction,
  updateShelveByPharmacyAction,
  deleteShelveByPharmacyAction,
  fetchShelvesByPharmacyAction,
} from "../../../redux/actions/shelveAction";
import { Modal, Button, Offcanvas, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import { FiEdit, FiHome, FiTrash } from "react-icons/fi";
import "./collectionShelves.css";

const CollectionShelves = () => {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [newShelf, setNewShelf] = useState({
    name: "",
    packageSize: "",
    shelfCapacity: "",
  });
  const [mode, setMode] = useState("add");
  const [selectedShelf, setSelectedShelf] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOffcanvasCreateEditModal, setShowOffcanvasCreateEditModal] =
    useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const shelves = useSelector((state) => state.shelve.shelves);
  const user = useSelector((state) => state.auth.user);

  const handleChange = (e) => {
    setNewShelf({ ...newShelf, [e.target.name]: e.target.value });
  };

  const handleDelete = (shelf) => {
    setSelectedShelf(shelf);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedShelf(null);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentShelves = shelves.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(shelves.length / itemsPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(createShelveByPharmacyAction(pharmacyId, newShelf))
        .then(() => {
          setNewShelf({
            name: "",
            shelfCapacity: "",
            packageSize: "",
          });
          dispatch(fetchShelvesByPharmacyAction(pharmacyId));
          dispatch(
            createNotificationByPharmacyAction(pharmacyId, {
              content: `New shelf '${newShelf.name}' has been created.`,
              user: user._id, // Replace with the actual user ID variable
              forRole: ["admin", "manager"],
              priority: "medium",
            })
          );
          // Reset form values
          setNewShelf({
            name: "",
            shelfCapacity: "",
            packageSize: "",
          });
          // Close modal
          setShowOffcanvasCreateEditModal(false);
        })
        .catch((error) => {
          console.error(error);
          ErrorNotification("Failed to create shelf.");
        });
    }
  };

  const validateForm = () => {
    const { name, shelfCapacity, packageSize } = newShelf;
    if (!name || !shelfCapacity || !packageSize) {
      ErrorNotification("All fields are required.");
      return false;
    }
    return true;
  };

  const handleConfirmDelete = () => {
    dispatch(deleteShelveByPharmacyAction(pharmacyId, selectedShelf._id))
      .then(() => {
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Shelf '${selectedShelf.name}' has been deleted.`,
            user: user._id, // Make sure user._id is correctly retrieved
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );
        handleCloseDeleteModal();
      })
      .catch((error) => {
        console.error("Error deleting shelf:", error);
      });
  };

  const getShelves = () => {
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((error) => {
      console.error("Error fetching shelves:", error);
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (
      selectedShelf &&
      selectedShelf._id &&
      newShelf.name &&
      newShelf.shelfCapacity
    ) {
      const shelfData = {
        name: newShelf.name,
        shelfCapacity: newShelf.shelfCapacity,
      };

      dispatch(
        updateShelveByPharmacyAction(pharmacyId, selectedShelf._id, shelfData)
      )
        .then(() => {
          setNewShelf({ name: "", packageSize: "", shelfCapacity: "" });
          setSelectedShelf(null);
          dispatch(
            createNotificationByPharmacyAction(pharmacyId, {
              content: `Shelf '${shelfData.name}' details updated successfully.`,
              user: user._id, // Replace with the actual user ID variable
              forRole: ["admin", "manager"],
              priority: "medium",
            })
          );
          // close modal
          setShowOffcanvasCreateEditModal(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.warning("All values not provided", { position: "top-right" });
    }
  };

  useEffect(() => {
    getShelves();
  }, []); // Add dispatch to dependency array

  const sortedShelves = shelves?.sort((a, b) => {
    const nameA = a.name ? a.name.toUpperCase() : "";
    const nameB = b.name ? b.name.toUpperCase() : "";
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const remainingCapacity = (shelfCapacity, occupiedCapacity) => {
    return shelfCapacity - occupiedCapacity;
  };

  return (
    <>
      <div className="shelves-container">
        <div className="shelves-header">
          <Button
            className="shelves-create-btn"
            onClick={() => {
              setNewShelf({
                name: "",
                shelfCapacity: "",
                packageSize: "",
              });
              setMode("add");
              setShowOffcanvasCreateEditModal(true);
            }}
          >
            <FiHome className="shelves-icon" />
            Create Shelf
          </Button>
          {/* Legends */}
          <div className="shelves-legends">
            <div className="shelves-legend-item">
              <FiEdit className="shelves-legend-icon shelves-legend-update" />
              <span className="shelves-legend-text">Update</span>
            </div>
            <div className="shelves-legend-item">
              <FiTrash className="shelves-legend-icon shelves-legend-delete" />
              <span className="shelves-legend-text">Delete</span>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="shelfModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="shelfModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Create a shelf
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body row">
                <div className="col-md-6 col-xs-12 form-field">
                  <label>Package Size:</label>
                  <select
                    name="packageSize"
                    value={newShelf.packageSize}
                    onChange={handleChange}
                    required
                    style={{ padding: "0.5rem", width: "80%" }}
                  >
                    <option>Select Shelf Category</option>
                    <option value="Very Small">Very Small</option>
                    <option value="Small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Very Large">Very Large</option>
                    <option value="Fridge">Fridge</option>
                    <option value="CD">CD</option>
                    <option value="CD+fridge">CD+fridge</option>
                  </select>
                </div>
                <div className="col-md-6 col-xs-12 form-field">
                  <label> Shelf Capacity</label>
                  <input
                    type="number"
                    name="shelfCapacity"
                    required
                    value={newShelf.shelfCapacity}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 col-xs-12 form-field">
                  <label>Shelf Name:</label>
                  <input
                    type="text"
                    name="name"
                    required
                    value={newShelf.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn save"
                  data-dismiss="modal"
                  onClick={(e) => {
                    mode === "add" ? handleSubmit(e) : handleEdit(e);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="shelves-table-container">
          <table className="table table-bordered w-100">
            <thead>
              <tr>
                <th>Name</th>
                <th>Shelf Capacity</th>
                <th>Occupied</th>
                <th>Remaining</th>
                <th>Package size</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentShelves.map((shelf) => (
                <tr key={shelf._id}>
                  <td>{shelf.name}</td>
                  <td>{shelf.shelfCapacity}</td>
                  <td>{shelf.occupiedCapacity}</td>
                  <td>
                    {remainingCapacity(
                      shelf.shelfCapacity,
                      shelf.occupiedCapacity
                    )}
                  </td>
                  <td>{shelf.packageSize}</td>
                  <td className="button-box">
                    <FiEdit
                      className="shelves-action-icon shelves-edit-icon"
                      style={{ marginRight: "0.5rem" }}
                      onClick={() => {
                        setNewShelf({
                          name: shelf.name,
                          shelfCapacity: shelf.shelfCapacity,
                          packageSize: shelf.packageSize,
                        });
                        setSelectedShelf(shelf);
                        setMode("edit");
                        setShowOffcanvasCreateEditModal(true);
                      }}
                    />
                    <FiTrash
                      className="shelves-action-icon shelves-delete-icon"
                      onClick={() => handleDelete(shelf)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="shelves-pagination">
          <Button
            className="shelves-pagination-btn"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="shelves-page-number">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            className="shelves-pagination-btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </div>

      {/* Create/Edit Shelf Modal */}
      <Offcanvas
        show={showOffcanvasCreateEditModal}
        onHide={() => {
          setShowOffcanvasCreateEditModal(false);
          setSelectedShelf(null);
          setNewShelf({
            name: "",
            shelfCapacity: "",
            packageSize: "",
          });
        }}
        placement="end"
        className="shelves-offcanvas"
      >
        <Offcanvas.Header closeButton className="shelves-offcanvas-header">
          <Offcanvas.Title className="shelves-offcanvas-title">
            {mode === "add" ? "Create Shelf" : "Edit Shelf"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="shelves-offcanvas-body">
          <Form>
            <Form.Group>
              <Form.Label>Package Size:</Form.Label>
              <Form.Control
                as="select"
                name="packageSize"
                value={newShelf.packageSize}
                onChange={handleChange}
                required
              >
                <option value="">Select Shelf Category</option>
                <option value="Very Small">Very Small</option>
                <option value="Small">Small</option>
                <option value="Medium">Medium</option>
                <option value="Large">Large</option>
                <option value="Very Large">Very Large</option>
                <option value="Fridge">Fridge</option>
                <option value="CD">CD</option>
                <option value="CD+fridge">CD+fridge</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Shelf Capacity:</Form.Label>
              <Form.Control
                type="number"
                name="shelfCapacity"
                value={newShelf.shelfCapacity}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Shelf Name:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newShelf.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Form>
        </Offcanvas.Body>
        <div className="shelves-offcanvas-footer">
          <Button
            variant="secondary"
            onClick={() => {
              setShowOffcanvasCreateEditModal(false);
              setSelectedShelf(null);
              setNewShelf({
                name: "",
                shelfCapacity: "",
                packageSize: "",
              });
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              mode === "add" ? handleSubmit(e) : handleEdit(e);
            }}
          >
            Save
          </Button>
        </div>
      </Offcanvas>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        centered
        className="shelves-delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Shelf</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this shelf?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CollectionShelves;
