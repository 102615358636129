import React, { useMemo } from "react";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import "./routeGraph.css";

const RouteGraph = ({ routes, deliveries }) => {
  // Prepare data by aggregating deliveries by route
  const data = useMemo(() => {
    const routeMap = routes.reduce((acc, route) => {
      acc[route.name] = { name: route.name, deliveryCount: 0 };
      return acc;
    }, {});

    deliveries.forEach((delivery) => {
      const routeName = delivery.routeRef?.name;
      if (routeName && routeMap[routeName]) {
        routeMap[routeName].deliveryCount++;
      }
    });

    return Object.values(routeMap);
  }, [routes, deliveries]);

  return (
    <div className="route-graph-container">
      <div className="route-dashboard-title">Delivery Routes</div>
      <ResponsiveContainer width="100%" height={400}>
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis />
          <Tooltip />
          <Radar
            name="Deliveries"
            dataKey="deliveryCount"
            stroke="#735dff"
            fill="#735dff"
            fillOpacity={0.6}
          />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RouteGraph;
