import { PHARMACY_TYPES } from "../constants/pharmacyTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  fetchPharmacy,
  fetchAllPharmacies,
  fetchAllUserPharmacies,
  addNewPharmacy,
  switchActivePharmacy,
} from "../../api/pharmacyApi";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../Components/Notifications/ToastNotifications";

// Fetch Pharmacy Action
export const fetchPharmacyAction = (pharmacyId) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await fetchPharmacy(pharmacyId);

    dispatch({
      type: PHARMACY_TYPES.FETCH_PHARMACY,
      payload: res.data,
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false, message: "Pharmacy fetched" },
    });
  } catch (error) {
    ErrorNotification(
      error.response?.data?.message || "Failed to fetch pharmacy"
    );

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        loading: false,
        message: error.response?.data?.message || "Failed to fetch pharmacy",
      },
    });
  }
};
//   Fetch All Pharmacy Action
export const fetchAllPharmacyAction = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await fetchAllPharmacies();
    dispatch({
      type: PHARMACY_TYPES.FETCH_ALL_PHARMACY,
      payload: res.data,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.message,
      },
    });
  }
};
// Fetch User's Pharmacies Action
export const fetchUserPharmaciesAction = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await fetchAllUserPharmacies();
    dispatch({
      type: PHARMACY_TYPES.FETCH_USER_PHARMACIES,
      payload: res.data,
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    ErrorNotification(
      error.response?.data?.error || "Failed to fetch pharmacies"
    );
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  }
};
// Create New Pharmacy Action
export const addNewPharmacyAction = (pharmacyData) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await addNewPharmacy(pharmacyData);

    if (res.status === 201) {
      // Check for successful creation
      dispatch({
        type: PHARMACY_TYPES.ADD_NEW_PHARMACY,
        payload: res.data,
      });

      // Fetch updated list of pharmacies
      dispatch(fetchUserPharmaciesAction());

      SuccessNotification("Pharmacy created successfully");
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
      return true;
    } else {
      throw new Error("Failed to create pharmacy");
    }
  } catch (error) {
    console.error("Pharmacy creation error:", error);
    ErrorNotification(
      error.response?.data?.error || "Failed to create pharmacy"
    );
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
    return false;
  }
};

// Switch Active Pharmacy Action
export const switchActivePharmacyAction =
  (userId, pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await switchActivePharmacy(userId, pharmacyId);
      dispatch({
        type: PHARMACY_TYPES.SET_ACTIVE_PHARMACY,
        payload: res.data.pharmacies,
      });

      SuccessNotification("Successfully switched pharmacy");
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      ErrorNotification(
        error.response?.data?.error || "Failed to switch pharmacy"
      );
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };
