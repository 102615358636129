import { Navigate, Route, Routes } from "react-router-dom";
// Normal Imports
import HomePage from "../Pages/common/homePage";
import Login from "../Pages/common/login";
import SignUp from "../Pages/common/signup";
import ForgotPassword from "../Pages/common/forgotPassword";
import NewPassword from "../Pages/common/newPassword";
import ContactUs from "../Pages/common/contactUs";
import Gdpr from "../Pages/common/gdpr";
import Privacy from "../Pages/common/privacy";
import Terms from "../Pages/common/terms";
// Admin Imports
import Dashboard from "../Pages/admin/dashboard";
import Appointment from "../Pages/admin/appointment";
import Plan from "../Pages/admin/plan";
import CareHome from "../Pages/admin/careHome";
import Delivery from "../Pages/admin/delivery";
import MedicineStorage from "../Pages/admin/medicineStorage";
import CollectionReports from "../Pages/admin/collectionReports";
import CollectionShelves from "../Pages/admin/collectionShelves";
import CreateMember from "../Pages/admin/createMember";
import CreatePatient from "../Pages/admin/createPatient";
import CreateRoutes from "../Pages/admin/createRoutes";
import RequestRx from "../Pages/admin/requestRx";
import UpdateRx from "../Pages/admin/updateRx";
import ReminderRx from "../Pages/admin/reminderRx";
import Handouts from "../Pages/admin/handouts";
import CreateVehicle from "../Pages/admin/createVehicle";
import RegVehicles from "../Pages/admin/regVehicles";
import TeamInviteAccept from "../Pages/admin/teamInviteAccept";
import MdsReports from "../Pages/admin/mdsReports";
import DeliveryReports from "../Pages/admin/deliveryReports";
import Profile from "../Pages/admin/profile";
import DeliveryList from "../Pages/admin/deliveryList";
import SubscriptionSuccess from "../Pages/admin/subscription/SubscriptionSuccess";
import PaymentCancel from "../Pages/admin/subscription/PaymentCancel";
import Notifications from "../Pages/admin/notifications";
import SwitchPharmacy from "../Pages/admin/switchPharmacy";
// Manager Imports
import ManagerDashboard from "../Pages/manager/dashboard";
import ManagerAppointment from "../Pages/manager/appointment";
import ManagerCareHome from "../Pages/manager/careHome";
import ManagerDelivery from "../Pages/manager/delivery";
import ManagerMedicineStorage from "../Pages/manager/medicineStorage";
import ManagerCollectionReports from "../Pages/manager/collectionReports";
import ManagerCollectionShelves from "../Pages/manager/collectionShelves";
import ManagerCreatePatient from "../Pages/manager/createPatient";
import ManagerCreateRoutes from "../Pages/manager/createRoutes";
import ManagerRequestRx from "../Pages/manager/requestRx";
import ManagerUpdateRx from "../Pages/manager/updateRx";
import ManagerHandouts from "../Pages/manager/handouts";
import ManagerCreateVehicle from "../Pages/manager/createVehicle";
import ManagerRegVehicles from "../Pages/manager/regVehicles";
import ManagerMdsReports from "../Pages/manager/mdsReports";
import ManagerDeliveryReports from "../Pages/manager/deliveryReports";
import ManagerProfile from "../Pages/manager/profile";
import ManagerDeliveryList from "../Pages/manager/deliveryList";
// Super Admin Import
import SuperAdminDashboard from "../Pages/superAdmin/dashboard";
import SuperAdminProfile from "../Pages/superAdmin/profile";
import UsersList from "../Pages/superAdmin/usersList";
import PharmacyList from "../Pages/superAdmin/pharmacyList";
import SuperCreateMember from "../Pages/superAdmin/management/create-member";
import MemberRole from "../Pages/superAdmin/management/member-role";
import SuperPlanCreate from "../Pages/superAdmin/plan/create-plan";
import SuperPlanView from "../Pages/superAdmin/plan/view-plan";
// Driver Import
import DriverDashboard from "../Pages/driver/dashboard";
import DriverProfile from "../Pages/driver/profile";
// Protected Routes
import ProtectedRoute from "./ProtectedRoutes";

const MainAppRoutes = () => {
  return (
    <Routes>
      {/* Common Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/new-password" element={<NewPassword />} />
      {/* <Route path="/" element={<HomePage />} /> */}
      {/* Redirect root to login */}
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/gdpr" element={<Gdpr />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/team/join" element={<TeamInviteAccept />} />
      {/* Admin Routes */}
      {/* Profile */}
      <Route
        path="/admin/:pharmacyId/profile/information"
        element={
          <ProtectedRoute element={<Profile />} requiredRoles={["admin"]} />
        }
      />
      {/* Dashboard */}
      <Route
        path="/admin/:pharmacyId/dashboard"
        element={
          <ProtectedRoute element={<Dashboard />} requiredRoles={["admin"]} />
        }
      />
      {/* Create Appointments */}
      <Route
        path="/admin/:pharmacyId/appointment"
        element={
          <ProtectedRoute element={<Appointment />} requiredRoles={["admin"]} />
        }
      />
      {/* Plan */}
      <Route
        path="/admin/:pharmacyId/plan"
        element={
          <ProtectedRoute element={<Plan />} requiredRoles={["admin"]} />
        }
      />
      {/* Subscription Success*/}
      <Route
        path="/admin/:pharmacyId/success"
        element={
          <ProtectedRoute
            element={<SubscriptionSuccess />}
            requiredRoles={["admin"]}
          />
        }
      />
      {/* Payment Failed*/}
      <Route
        path="/admin/:pharmacyId/cancel"
        element={
          <ProtectedRoute
            element={<PaymentCancel />}
            requiredRoles={["admin"]}
          />
        }
      />
      {/* Create Member */}
      <Route
        path="/admin/:pharmacyId/create-member"
        element={
          <ProtectedRoute
            element={<CreateMember />}
            requiredRoles={["admin"]}
          />
        }
      />

      {/* Create Patient */}
      <Route
        path="/admin/:pharmacyId/create-patient"
        element={
          <ProtectedRoute
            element={<CreatePatient />}
            requiredRoles={["admin"]}
          />
        }
      />
      {/* Delivery */}
      <Route
        path="/admin/:pharmacyId/create-delivery"
        element={
          <ProtectedRoute element={<Delivery />} requiredRoles={["admin"]} />
        }
      />

      {/* Create Vehicles */}
      <Route
        path="/admin/:pharmacyId/create-vehicle"
        element={
          <ProtectedRoute
            element={<CreateVehicle />}
            requiredRoles={["admin"]}
          />
        }
      />
      {/* Delivery List*/}
      <Route
        path="/admin/:pharmacyId/delivery-list"
        element={
          <ProtectedRoute
            element={<DeliveryList />}
            requiredRoles={["admin"]}
          />
        }
      />

      {/* Collection Shelves */}
      <Route
        path="/admin/:pharmacyId/collection-shelves"
        element={
          <ProtectedRoute
            element={<CollectionShelves />}
            requiredRoles={["admin"]}
          />
        }
      />

      {/* Rx Storage */}
      <Route
        path="/admin/:pharmacyId/storage"
        element={
          <ProtectedRoute
            element={<MedicineStorage />}
            requiredRoles={["admin"]}
          />
        }
      />
      {/* Care Home */}
      <Route
        path="/admin/:pharmacyId/care-home"
        element={
          <ProtectedRoute element={<CareHome />} requiredRoles={["admin"]} />
        }
      />
      {/* Create Routes */}
      <Route
        path="/admin/:pharmacyId/create-routes"
        element={
          <ProtectedRoute
            element={<CreateRoutes />}
            requiredRoles={["admin"]}
          />
        }
      />
      {/* Register Vehicles */}
      <Route
        path="/admin/:pharmacyId/register-vehicles"
        element={
          <ProtectedRoute element={<RegVehicles />} requiredRoles={["admin"]} />
        }
      />
      {/* Request Rx */}
      <Route
        path="/admin/:pharmacyId/request-rx"
        element={
          <ProtectedRoute element={<RequestRx />} requiredRoles={["admin"]} />
        }
      />
      {/* Update Rx */}
      <Route
        path="/admin/:pharmacyId/update-rx"
        element={
          <ProtectedRoute element={<UpdateRx />} requiredRoles={["admin"]} />
        }
      />
      {/* Update Rx */}
      <Route
        path="/admin/:pharmacyId/reminder-rx"
        element={
          <ProtectedRoute element={<ReminderRx />} requiredRoles={["admin"]} />
        }
      />
      {/* Handouts */}
      <Route
        path="/admin/:pharmacyId/handouts"
        element={
          <ProtectedRoute element={<Handouts />} requiredRoles={["admin"]} />
        }
      />
      {/* Collection Reports */}
      <Route
        path="/admin/:pharmacyId/collection-reports"
        element={
          <ProtectedRoute
            element={<CollectionReports />}
            requiredRoles={["admin"]}
          />
        }
      />
      {/* Delivery Reports */}
      <Route
        path="/admin/:pharmacyId/delivery-reports"
        element={
          <ProtectedRoute
            element={<DeliveryReports />}
            requiredRoles={["admin"]}
          />
        }
      />
      {/* Mds Reports */}
      <Route
        path="/admin/:pharmacyId/mds-reports"
        element={
          <ProtectedRoute element={<MdsReports />} requiredRoles={["admin"]} />
        }
      />
      {/* Notifications */}
      <Route
        path="/admin/:pharmacyId/notifications"
        element={
          <ProtectedRoute
            element={<Notifications />}
            requiredRoles={["admin"]}
          />
        }
      />
      {/* Switch Pharmacy */}
      <Route
        path="/admin/:pharmacyId/switch-pharmacy"
        element={
          <ProtectedRoute
            element={<SwitchPharmacy />}
            requiredRoles={["admin"]}
          />
        }
      />
      {/* Manager Routes */}
      {/* Manager Profile */}
      <Route
        path="/manager/:pharmacyId/profile/information"
        element={
          <ProtectedRoute
            element={<ManagerProfile />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Dashboard */}
      <Route
        path="/manager/:pharmacyId/dashboard"
        element={
          <ProtectedRoute
            element={<ManagerDashboard />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Create Appointments */}
      <Route
        path="/manager/:pharmacyId/appointment"
        element={
          <ProtectedRoute
            element={<ManagerAppointment />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/*Manager Create Patient */}
      <Route
        path="/manager/:pharmacyId/create-patient"
        element={
          <ProtectedRoute
            element={<ManagerCreatePatient />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Delivery */}
      <Route
        path="/manager/:pharmacyId/create-delivery"
        element={
          <ProtectedRoute
            element={<ManagerDelivery />}
            requiredRoles={["manager"]}
          />
        }
      />

      {/* Manager Create Vehicles */}
      <Route
        path="/manager/:pharmacyId/create-vehicle"
        element={
          <ProtectedRoute
            element={<ManagerCreateVehicle />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Delivery List*/}
      <Route
        path="/manager/:pharmacyId/delivery-list"
        element={
          <ProtectedRoute
            element={<ManagerDeliveryList />}
            requiredRoles={["manager"]}
          />
        }
      />

      {/* Manager Collection Shelves */}
      <Route
        path="/manager/:pharmacyId/collection-shelves"
        element={
          <ProtectedRoute
            element={<ManagerCollectionShelves />}
            requiredRoles={["manager"]}
          />
        }
      />

      {/*Manager Rx Storage */}
      <Route
        path="/manager/:pharmacyId/storage"
        element={
          <ProtectedRoute
            element={<ManagerMedicineStorage />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Care Home */}
      <Route
        path="/manager/:pharmacyId/care-home"
        element={
          <ProtectedRoute
            element={<ManagerCareHome />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/*Manager  Create Routes */}
      <Route
        path="/manager/:pharmacyId/create-routes"
        element={
          <ProtectedRoute
            element={<ManagerCreateRoutes />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Register Vehicles */}
      <Route
        path="/manager/:pharmacyId/register-vehicles"
        element={
          <ProtectedRoute
            element={<ManagerRegVehicles />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Request Rx */}
      <Route
        path="/manager/:pharmacyId/request-rx"
        element={
          <ProtectedRoute
            element={<ManagerRequestRx />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Update Rx */}
      <Route
        path="/manager/:pharmacyId/update-rx"
        element={
          <ProtectedRoute
            element={<ManagerUpdateRx />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Handouts */}
      <Route
        path="/manager/:pharmacyId/handouts"
        element={
          <ProtectedRoute
            element={<ManagerHandouts />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Collection Reports */}
      <Route
        path="/manager/:pharmacyId/collection-reports"
        element={
          <ProtectedRoute
            element={<ManagerCollectionReports />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Delivery Reports */}
      <Route
        path="/manager/:pharmacyId/delivery-reports"
        element={
          <ProtectedRoute
            element={<ManagerDeliveryReports />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Manager Mds Reports */}
      <Route
        path="/manager/:pharmacyId/mds-reports"
        element={
          <ProtectedRoute
            element={<ManagerMdsReports />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Driver Routes */}
      {/* Driver Profile */}
      <Route
        path="/driver/:pharmacyId/profile/information"
        element={
          <ProtectedRoute
            element={<DriverProfile />}
            requiredRoles={["driver"]}
          />
        }
      />
      {/* Driver Dashboard */}
      <Route
        path="/driver/:pharmacyId/dashboard"
        element={
          <ProtectedRoute
            element={<DriverDashboard />}
            requiredRoles={["driver"]}
          />
        }
      />

      {/* Super Admin Routes */}
      {/* Super Admin Profile */}
      <Route
        path="/super-admin/profile/information"
        element={
          <ProtectedRoute
            element={<SuperAdminProfile />}
            requiredRoles={["manager"]}
          />
        }
      />
      {/* Super Admin Dashboard */}
      <Route
        path="/super-admin/dashboard"
        element={
          <ProtectedRoute
            element={<SuperAdminDashboard />}
            requiredRoles={["superadmin"]}
          />
        }
      />
      {/* Super Admin Profile */}
      <Route
        path="/super-admin/profile/information"
        element={
          <ProtectedRoute
            element={<Profile />}
            requiredRoles={["superadmin"]}
          />
        }
      />
      {/* Super Admin Create Plan */}
      <Route
        path="/super-admin/plan/create-plan"
        element={
          <ProtectedRoute
            element={<SuperPlanCreate />}
            requiredRoles={["superadmin"]}
          />
        }
      />
      {/* Super Admin View Plan */}
      <Route
        path="/super-admin/plan/view-plan"
        element={
          <ProtectedRoute
            element={<SuperPlanView />}
            requiredRoles={["superadmin"]}
          />
        }
      />
      {/* Super Admin Create Member */}
      <Route
        path="/super-admin/management/create-member"
        element={
          <ProtectedRoute
            element={<SuperCreateMember />}
            requiredRoles={["superadmin"]}
          />
        }
      />
      {/* Super Admin Member Role */}
      <Route
        path="/super-admin/management/member-role"
        element={
          <ProtectedRoute
            element={<MemberRole />}
            requiredRoles={["superadmin"]}
          />
        }
      />
      {/* Users List */}
      <Route
        path="/super-admin/users-list"
        element={
          <ProtectedRoute
            element={<UsersList />}
            requiredRoles={["superadmin"]}
          />
        }
      />
      {/* Pharmacy List */}
      <Route
        path="/super-admin/pharmacy-list"
        element={
          <ProtectedRoute
            element={<PharmacyList />}
            requiredRoles={["superadmin"]}
          />
        }
      />
    </Routes>
  );
};

export default MainAppRoutes;
