import React from "react";
import "./adminDashboardBox.css";
import { NavLink } from "react-router-dom";

function AdminDashboardBox({ indicators, pharmacyId }) {
  return (
    <div
      className="container-fluid"
      style={{
        padding: 0,
      }}
    >
      <div className="management-dashboard-title">Management</div>
      <div className="dashboardMainBox">
        {indicators.map((indicator, index) => (
          <div
            key={index}
            className="dashSubBox"
            // style={{ backgroundColor: indicator?.backgroundColor }}
          >
            <div className="dashIconMainBox">
              <div
                className="dashIconContainer"
                style={{ backgroundColor: indicator?.iconBackgroundColor }}
              >
                {React.cloneElement(indicator?.icon, {
                  style: { fontSize: "1.7rem", color: "fff" },
                })}
              </div>
              {indicator.title === "Total Care Homes" && (
                <NavLink
                  to={`/admin/${pharmacyId}/care-home`}
                  className="dashBoardNavLink color-muted"
                >
                  View all care homes
                </NavLink>
              )}
              {indicator.title === "Total Shelves" && (
                <NavLink
                  to={`/admin/${pharmacyId}/collection-shelves`}
                  className="dashBoardNavLink color-muted"
                >
                  View all shelves
                </NavLink>
              )}
              {indicator.title === "Total Routes" && (
                <NavLink
                  to={`/admin/${pharmacyId}/create-routes`}
                  className="dashBoardNavLink color-muted"
                >
                  View all routes
                </NavLink>
              )}
              {indicator.title === "Total Vehicles" && (
                <NavLink
                  to={`/admin/${pharmacyId}/vehicles`}
                  className="dashBoardNavLink color-muted"
                >
                  View all vehicles
                </NavLink>
              )}
              {indicator.title === "Total Members" && (
                <NavLink
                  to={`/admin/${pharmacyId}/members`}
                  className="dashBoardNavLink color-muted"
                >
                  View all members
                </NavLink>
              )}
            </div>
            <div className="dashInfoBox">
              <p className="dashTitle">{indicator?.title}</p>
              <p className="dashCount">{indicator?.count}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminDashboardBox;
