import React, { useMemo, useState, useCallback } from "react";
import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { format, subMonths } from "date-fns";
import "./patientLineGraph.css";

const PatientLineGraph = ({ patients }) => {
  const [monthsToShow, setMonthsToShow] = useState(3);
  const [highlightedLine, setHighlightedLine] = useState(null); // State to track highlighted line

  const getMonths = (monthsBack) => {
    let months = [];
    let currentDate = new Date();

    for (let i = monthsBack - 1; i >= 0; i--) {
      let pastDate = subMonths(currentDate, i);
      months.push(format(pastDate, "MMM yyyy"));
    }

    return months;
  };

  const data = useMemo(() => {
    const months = getMonths(monthsToShow);
    const initialData = months.map((month) => ({ month, normal: 0, mds: 0 }));

    if (patients && patients.length > 0) {
      patients.forEach((patient) => {
        const patientMonthYear = format(
          new Date(patient.createdAt),
          "MMM yyyy"
        );
        const dataIndex = initialData.findIndex(
          (data) => data.month === patientMonthYear
        );

        if (dataIndex !== -1) {
          const patientType = patient.patientType || "normal"; // Default to 'normal' if patientType is not defined
          initialData[dataIndex][patientType]++;
        }
      });
    }

    return initialData;
  }, [patients, monthsToShow]);

  // Function to assign "active" class based on the current monthsToShow state
  const getButtonClass = (months) => {
    return monthsToShow === months ? "active-months" : "";
  };

  const handleLegendMouseEnter = useCallback((o) => {
    setHighlightedLine(o.dataKey);
  }, []);

  const handleLegendMouseLeave = useCallback(() => {
    setHighlightedLine(null);
  }, []);

  return (
    <div className="line-graph-container">
      <div className="patient-dashboard-title">Patients</div>
      <div className="linegraph-title-box">
        <div className="linegraph-legend"></div>
        <div className="dashboard-linegraph-title">Order Statistics </div>
        <div className="lineGraphButtonBox">
          <button
            className={getButtonClass(3)}
            onClick={() => setMonthsToShow(3)}
          >
            3 Months
          </button>
          <button
            className={getButtonClass(6)}
            onClick={() => setMonthsToShow(6)}
          >
            6 Months
          </button>
          <button
            className={getButtonClass(12)}
            onClick={() => setMonthsToShow(12)}
          >
            12 Months
          </button>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <Tooltip />
          <Legend
            onMouseEnter={handleLegendMouseEnter}
            onMouseLeave={handleLegendMouseLeave}
            align="left" /* Aligns the legend to the left */
            verticalAlign="top" /* Positions the legend at the top */
            wrapperStyle={{
              paddingLeft: 0,
              transform: "translateY(-35px)",
              maxWidth: "200px",
            }}
            formatter={(value, entry, index) => {
              return (
                <span
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Figtree-SemiBold",
                    marginRight: "10px",
                    textTransform: "capitalize",
                    textShadow: "1px 1px 1px #fff",
                    cursor: "pointer",
                  }}
                >
                  {value}
                </span>
              );
            }}
          />
          <Line
            type="monotone"
            dataKey="normal"
            stroke="#735dff"
            strokeWidth={2}
            opacity={highlightedLine && highlightedLine !== "normal" ? 0.2 : 1}
          />
          <Line
            type="monotone"
            dataKey="mds"
            stroke="#ff5a29"
            strokeWidth={2}
            opacity={highlightedLine && highlightedLine !== "mds" ? 0.2 : 1}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PatientLineGraph;
