// Reducers/pharmacyReducer.js

import { PHARMACY_TYPES } from "../constants/pharmacyTypes";

const initialState = {
  pharmacy: [],
  pharmacies: [],
  activePharmacy: null,
};

const pharmacyReducer = (state = initialState, action) => {
  switch (action.type) {
    case PHARMACY_TYPES.FETCH_PHARMACY:
      return {
        ...state,
        pharmacy: action.payload,
      };
    case PHARMACY_TYPES.FETCH_ALL_PHARMACY:
      return {
        ...state,
        pharmacy: action.payload,
      };
    case PHARMACY_TYPES.FETCH_USER_PHARMACIES:
      return {
        ...state,
        pharmacies: action.payload,
        activePharmacy: action.payload.find((p) => p.isActive),
      };

    case PHARMACY_TYPES.ADD_NEW_PHARMACY:
      return {
        ...state,
        pharmacies: [...state.pharmacies, action.payload],
      };

    case PHARMACY_TYPES.SET_ACTIVE_PHARMACY:
      return {
        ...state,
        pharmacies: action.payload,
        activePharmacy: action.payload.find((p) => p.isActive),
      };

    default:
      return state;
  }
};

export default pharmacyReducer;
