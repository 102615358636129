import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, Button, Form, Offcanvas } from "react-bootstrap";
import {
  createRouteByPharmacyAction,
  updateRouteByPharmacyAction,
  deleteRouteByPharmacyAction,
  fetchRoutesByPharmacyAction,
} from "../../../redux/actions/routeAction";
import { fetchVehiclesByPharmacyAction } from "../../../redux/actions/vehicleAction";
import { fetchMembersByPharmacyAction } from "../../../redux/actions/memberAction";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import Overlay from "../../../Components/Overlay";
import { FiEdit, FiEye, FiHome, FiTrash } from "react-icons/fi";
import "./createRoutes.css";

function CreateRoutes() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [selectedRoute, setSelectedRoute] = useState({});
  const [routeName, setRouteName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [assignedDriverId, setAssignedDriverId] = useState("");
  const [assignedVehicleId, setAssignedVehicleId] = useState("");

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showOffcanvasCreateModal, setShowOffcanvasCreateModal] =
    useState(false);
  const [showOffcanvasDetailsModal, setShowOffcanvasDetailsModal] =
    useState(false);
  const [showOffcanvasEditModal, setShowOffcanvasEditModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const members = useSelector((state) => state?.member?.members);
  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const routes = useSelector((state) => state.route.routes);
  const user = useSelector((state) => state.auth.user);
  const userCurrentPlan = user.currentPlan;
  const drivers = members.filter((member) => member.role === "driver");

  const fetchMembers = () => {
    try {
      dispatch(fetchMembersByPharmacyAction(pharmacyId));
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };
  const getRoutes = () => {
    try {
      dispatch(fetchRoutesByPharmacyAction(pharmacyId));
    } catch (error) {
      console.log("Routes could not be fetched");
    }
  };

  const fetchVehicles = () => {
    try {
      dispatch(fetchVehiclesByPharmacyAction(pharmacyId));
    } catch (error) {
      console.log("Vehicles could not be fetched");
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRoutes = routes.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(routes.length / itemsPerPage);
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  const handleRouteNameChange = (e) => {
    setRouteName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleIsActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const handleAssignedDriverChange = (e) => {
    setAssignedDriverId(e.target.value);
  };

  const handleAssignedVehicleChange = (e) => {
    setAssignedVehicleId(e.target.value);
  };

  const handleShowDetails = (route) => {
    console.log(route, "route clicked");
    setSelectedRoute(route);
    setShowOffcanvasDetailsModal(true); // Assuming you're using this state to control the visibility
  };

  const findMember = (id) => {
    // Find the member with the matching ID
    const member = members.find((member) => member._id === id);
    // Return the member's name if found, otherwise return a default string
    return member ? member.name : "Not Assigned";
  };

  const findVehicle = (id) => {
    const vehicle = vehicles.find((vehicle) => vehicle._id === id);

    return vehicle ? vehicle.vehicleName : "No Vehicle Assigned";
  };

  const handleSave = async () => {
    const newRoute = {
      name: routeName,
      description: description,
      activeStatus: isActive,
      assignedDriverId: assignedDriverId ? assignedDriverId : null,
      assignedVehicleId: assignedVehicleId ? assignedVehicleId : null,
    };

    dispatch(createRouteByPharmacyAction(pharmacyId, newRoute))
      .then(() => {
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `New route '${newRoute.name}' has been created.`,
            user: user._id, // Replace with actual user ID variable
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );
        setRouteName("");
        setDescription("");
        setIsActive(false);
        setAssignedDriverId("");
        setAssignedVehicleId("");
        setShowOffcanvasCreateModal(false);
      })
      .catch((error) => {
        console.error("Error creating route:", error);
      });
  };

  const handleEditSave = () => {
    const updatedRoute = {
      name: routeName,
      description: description,
      activeStatus: isActive,
      assignedDriverId: assignedDriverId ? assignedDriverId : null,
      assignedVehicleId: assignedVehicleId ? assignedVehicleId : null,
    };

    dispatch(
      updateRouteByPharmacyAction(pharmacyId, selectedRoute._id, updatedRoute)
    )
      .then(() => {
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Route '${updatedRoute.name}' details updated successfully.`,
            user: user._id, // Replace with actual user ID variable
            forRole: ["admin", "manager"],
            priority: "medium",
          })
        );
        setRouteName("");
        setDescription("");
        setIsActive(false);
        setAssignedDriverId("");
        setAssignedVehicleId("");
        setShowOffcanvasEditModal(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const handleDelete = () => {
    dispatch(deleteRouteByPharmacyAction(pharmacyId, selectedRoute._id))
      .then(() => {
        setSelectedRoute({});
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Route '${selectedRoute.name}' has been deleted.`,
            user: user._id, // Replace with actual user ID variable
            forRole: ["admin"],
            priority: "high",
          })
        );
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  // Fetch drivers when the component mounts
  useEffect(() => {
    fetchMembers();
    getRoutes();
    fetchVehicles();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      } else {
        document.body.classList.remove("no-interaction");
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [userCurrentPlan]);

  useEffect(() => {
    const handleClicks = () => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      }
    };

    document.addEventListener("click", handleClicks);

    return () => document.removeEventListener("click", handleClicks);
  }, [userCurrentPlan]);

  return (
    <>
      <div className="routes-container">
        {userCurrentPlan === "Free" && <Overlay />}
        <div className="routes-header">
          <button
            onClick={() => setShowOffcanvasCreateModal(true)}
            className="routes-create-btn"
          >
            <FiHome className="routes-icon" />
            Create Route
          </button>
          {/* Legends */}
          <div className="routes-legends">
            <div className="routes-legend-item">
              <FiEye className="routes-legend-icon routes-legend-view" />
              <span className="routes-legend-text">View</span>
            </div>
            <div className="routes-legend-item">
              <FiEdit className="routes-legend-icon routes-legend-update" />
              <span className="routes-legend-text">Update</span>
            </div>
            <div className="routes-legend-item">
              <FiTrash className="routes-legend-icon routes-legend-delete" />
              <span className="routes-legend-text">Delete</span>
            </div>
          </div>
        </div>
        <div className="routes-table-container">
          <table className="table table-bordered w-100">
            <thead>
              <tr>
                <th>Route Name</th>
                <th>Description</th>
                <th>Active</th>
                <th>Assigned Vehicle</th>
                <th>Assigned Driver</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRoutes.map((route, index) => {
                return (
                  <tr key={index}>
                    <td
                      data-toggle="modal"
                      data-target="#routeDetailsModalCenter"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedRoute(route);
                      }}
                    >
                      {route.name}
                    </td>
                    <td
                      data-toggle="modal"
                      data-target="#routeDetailsModalCenter"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedRoute(route);
                      }}
                    >
                      {route.description}
                    </td>
                    <td
                      data-toggle="modal"
                      data-target="#routeDetailsModalCenter"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedRoute(route);
                      }}
                    >
                      {route.activeStatus ? "Yes" : "No"}
                    </td>
                    <td
                      data-toggle="modal"
                      data-target="#routeDetailsModalCenter"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedRoute(route);
                      }}
                    >
                      {findVehicle(route.assignedVehicleId)}
                    </td>
                    <td
                      data-toggle="modal"
                      data-target="#routeDetailsModalCenter"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedRoute(route);
                      }}
                    >
                      {findMember(route.assignedDriverId)}
                    </td>
                    <td>
                      <FiEye
                        className="routes-action-icon routes-view-icon"
                        style={{ marginRight: "0.5rem" }}
                        onClick={() => handleShowDetails(route)} // Pass the 'route' object correctly
                      />
                      <FiEdit
                        className="routes-action-icon routes-edit-icon"
                        style={{ marginRight: "0.5rem" }}
                        onClick={() => {
                          setSelectedRoute(route);
                          setRouteName(route.name);
                          setDescription(route.description);
                          setIsActive(route.activeStatus);
                          setAssignedDriverId(route.assignedDriverId);
                          setAssignedVehicleId(route.assignedVehicleId);
                          setShowOffcanvasEditModal(true);
                        }}
                      />

                      <FiTrash
                        className="routes-action-icon routes-delete-icon"
                        onClick={() => {
                          setSelectedRoute(route);
                          setShowDeleteModal(true);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="routes-pagination">
          <Button
            className="routes-pagination-btn"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="routes-page-number">
            Page {currentPage} of {totalPages}
          </span>
          <Button
            className="routes-pagination-btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </div>
      {/* Create a Route Modal */}
      <Offcanvas
        show={showOffcanvasCreateModal}
        onHide={() => setShowOffcanvasCreateModal(false)}
        placement="end"
        className="routes-offcanvas"
      >
        <Offcanvas.Header closeButton className="routes-offcanvas-header">
          <Offcanvas.Title>Create a Route</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="routes-offcanvas-body">
          <div className="form-group">
            <label htmlFor="routeName">Route Name:</label>
            <input
              type="text"
              className="form-control"
              id="routeName"
              required
              value={routeName}
              onChange={handleRouteNameChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <input
              type="text"
              className="form-control"
              id="description"
              required
              value={description}
              onChange={handleDescriptionChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="assignedDriver">Assigned Driver:</label>
            <select
              className="form-control"
              id="assignedDriver"
              value={assignedDriverId}
              required
              onChange={handleAssignedDriverChange}
            >
              <option value="">Select a driver</option>
              {drivers.map((driver) => (
                <option key={driver._id} value={driver._id}>
                  {driver.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="assignedVehicle">Assigned Vehicle:</label>
            <select
              className="form-control"
              id="assignedVehicle"
              value={assignedVehicleId}
              required
              onChange={handleAssignedVehicleChange}
            >
              <option value="">Select a Vehicle</option>
              {vehicles.map((vehicle) => (
                <option key={vehicle._id} value={vehicle._id}>
                  {vehicle.vehicleName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="isActive"
              checked={isActive}
              onChange={handleIsActiveChange}
            />
            <label className="form-check-label" htmlFor="isActive">
              Active
            </label>
          </div>
        </Offcanvas.Body>
        <div className="routes-offcanvas-footer">
          <button
            className="btn btn-light"
            onClick={() => setShowOffcanvasCreateModal(false)}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={handleSave}>
            Create
          </button>
        </div>
      </Offcanvas>

      {/* Route Details Modal */}
      <Offcanvas
        className="routes-offcanvas"
        show={showOffcanvasDetailsModal}
        onHide={() => setShowOffcanvasDetailsModal(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton className="routes-offcanvas-header">
          <Offcanvas.Title>Route Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="routes-offcanvas-body">
          {selectedRoute && (
            <>
              <p>
                <strong>Route Name:</strong> {selectedRoute.name}
              </p>
              <p>
                <strong>Description:</strong> {selectedRoute.description}
              </p>
              <p>
                <strong>Active:</strong>{" "}
                {selectedRoute.activeStatus ? "Yes" : "No"}
              </p>
              <p>
                <strong>Assigned Driver:</strong>{" "}
                {findVehicle(selectedRoute.assignedVehicleId)}
              </p>
              <p>
                <strong>Assigned Vehicle:</strong>{" "}
                {findMember(selectedRoute.assignedDriverId)}
              </p>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      {/* Edit Modal */}
      <Offcanvas
        show={showOffcanvasEditModal}
        onHide={() => setShowOffcanvasEditModal(false)}
        placement="end"
        className="routes-offcanvas"
      >
        <Offcanvas.Header closeButton className="routes-offcanvas-header">
          <Offcanvas.Title>Edit Route</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="routes-offcanvas-body">
          <div className="form-group">
            <label htmlFor="editRouteName">Route Name:</label>
            <input
              type="text"
              className="form-control"
              id="editRouteName"
              required
              value={routeName}
              onChange={handleRouteNameChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="editDescription">Description:</label>
            <input
              type="text"
              className="form-control"
              id="editDescription"
              required
              value={description}
              onChange={handleDescriptionChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="editAssignedDriver">Assigned Driver:</label>
            <select
              className="form-control"
              id="editAssignedDriver"
              value={assignedDriverId}
              required
              onChange={handleAssignedDriverChange}
            >
              <option value="">Select a driver</option>
              {drivers.map((driver) => (
                <option key={driver._id} value={driver._id}>
                  {driver.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="editAssignedVehicle">Assigned Vehicle:</label>
            <select
              className="form-control"
              id="editAssignedVehicle"
              value={assignedVehicleId}
              required
              onChange={handleAssignedVehicleChange}
            >
              <option value="">Select a Vehicle</option>
              {vehicles.map((vehicle) => (
                <option key={vehicle._id} value={vehicle._id}>
                  {vehicle.vehicleName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="editIsActive"
              checked={isActive}
              onChange={handleIsActiveChange}
            />
            <label className="form-check-label" htmlFor="editIsActive">
              Active
            </label>
          </div>
        </Offcanvas.Body>
        <div className="routes-offcanvas-footer">
          <button
            className="btn btn-light"
            onClick={() => setShowOffcanvasEditModal(false)}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={handleEditSave}>
            Save Changes
          </button>
        </div>
      </Offcanvas>

      {/* Delete Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
        className="routes-delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this route?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className="btn delete"
            onClick={() => {
              handleDelete();
              setShowDeleteModal(false);
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateRoutes;
