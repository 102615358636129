import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Offcanvas } from "react-bootstrap";
import { FiPlusCircle, FiCheck } from "react-icons/fi";
import { BsBuilding } from "react-icons/bs";
import Overlay from "../../../Components/Overlay";
import {
  fetchUserPharmaciesAction,
  addNewPharmacyAction,
  switchActivePharmacyAction,
} from "../../../redux/actions/pharmacyActions";
import "./switchPharmacy.css";

const SwitchPharmacy = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [pharmacyName, setPharmacyName] = useState("");
  const [pharmacyAddress, setPharmacyAddress] = useState("");
  const [pharmacyEmail, setPharmacyEmail] = useState("");
  const [pharmacyPhone, setPharmacyPhone] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const pharmaciesPerPage = 4;

  const user = useSelector((state) => state.auth.user);
  const userCurrentPlan = user.currentPlan;
  const pharmacies = useSelector((state) => state.pharmacy.pharmacies) || [];

  useEffect(() => {
    dispatch(fetchUserPharmaciesAction());
  }, [dispatch]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCreatePharmacy = async () => {
    const pharmacyData = {
      pharmacyName,
      pharmacyAddress,
      pharmacyEmail,
      pharmacyPhone,
    };

    const success = await dispatch(addNewPharmacyAction(pharmacyData));
    if (success) {
      clearFields();
      handleClose();
      dispatch(fetchUserPharmaciesAction());
    }
  };

  const handleSwitchPharmacy = async (pharmacyId) => {
    await dispatch(switchActivePharmacyAction(pharmacyId));
    dispatch(fetchUserPharmaciesAction());
  };

  const clearFields = () => {
    setPharmacyName("");
    setPharmacyAddress("");
    setPharmacyEmail("");
    setPharmacyPhone("");
  };

  const indexOfLastPharmacy = currentPage * pharmaciesPerPage;
  const indexOfFirstPharmacy = indexOfLastPharmacy - pharmaciesPerPage;
  const currentPharmacies = pharmacies.slice(
    indexOfFirstPharmacy,
    indexOfLastPharmacy
  );
  const totalPages = Math.ceil(pharmacies.length / pharmaciesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <>
      {userCurrentPlan === "Free" && <Overlay />}
      <div className="switch-pharmacy-container">
        <div className="switch-pharmacy-header">
          <button className="switch-pharmacy-invite-btn" onClick={handleShow}>
            <FiPlusCircle className="switch-pharmacy-invite-icon" />
            Add New Pharmacy
          </button>

          <div className="switch-pharmacy-legends">
            <div className="switch-pharmacy-legend-item">
              <BsBuilding className="switch-pharmacy-legend-icon switch-pharmacy-legend-view" />
              <span className="switch-pharmacy-legend-text">
                Available Pharmacies
              </span>
            </div>
            <div className="switch-pharmacy-legend-item">
              <FiCheck className="switch-pharmacy-legend-icon switch-pharmacy-legend-update" />
              <span className="switch-pharmacy-legend-text">
                Active Pharmacy
              </span>
            </div>
          </div>
        </div>

        <div className="switch-pharmacy-cards-container mt-4">
          <div className="row g-4">
            {currentPharmacies.map((pharmacy) => (
              <div key={pharmacy._id} className="col-md-6 col-lg-4">
                <div
                  className={`switch-pharmacy-card ${
                    pharmacy.isActive ? "switch-pharmacy-card-active" : ""
                  }`}
                >
                  <div className="switch-pharmacy-card-header">
                    <h3>{pharmacy.pharmacyName}</h3>
                    {pharmacy.isActive && (
                      <FiCheck className="switch-pharmacy-active-icon" />
                    )}
                  </div>
                  <div className="switch-pharmacy-card-body">
                    <p>
                      <strong>ID:</strong> {pharmacy._id}
                    </p>
                    <p>
                      <strong>Address:</strong> {pharmacy.pharmacyAddress}
                    </p>
                    <p>
                      <strong>Email:</strong> {pharmacy.pharmacyEmail}
                    </p>
                    <p>
                      <strong>Phone:</strong> {pharmacy.pharmacyPhone}
                    </p>
                  </div>
                  {!pharmacy.isActive && (
                    <button
                      className="switch-pharmacy-switch-btn"
                      onClick={() => handleSwitchPharmacy(pharmacy._id)}
                    >
                      Switch to this pharmacy
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="switch-pharmacy-pagination">
        <Button
          className="switch-pharmacy-pagination-btn"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="switch-pharmacy-page-number">
          Page {currentPage} of {totalPages}
        </span>
        <Button
          className="switch-pharmacy-pagination-btn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>

      {/* Add Pharmacy Modal */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="switch-pharmacy-offcanvas"
      >
        <Offcanvas.Header
          closeButton
          className="switch-pharmacy-offcanvas-header"
        >
          <Offcanvas.Title>Add New Pharmacy</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="switch-pharmacy-offcanvas-body">
          <Form>
            <Form.Group className="mb-3" controlId="pharmacyName">
              <Form.Label>Pharmacy Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter pharmacy name"
                value={pharmacyName}
                onChange={(e) => setPharmacyName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="pharmacyAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter pharmacy address"
                value={pharmacyAddress}
                onChange={(e) => setPharmacyAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="pharmacyEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter pharmacy email"
                value={pharmacyEmail}
                onChange={(e) => setPharmacyEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="pharmacyPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter pharmacy phone"
                value={pharmacyPhone}
                onChange={(e) => setPharmacyPhone(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Offcanvas.Body>
        <div className="switch-pharmacy-offcanvas-footer">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreatePharmacy}>
            Create Pharmacy
          </Button>
        </div>
      </Offcanvas>
    </>
  );
};

export default SwitchPharmacy;
